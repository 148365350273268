import React, { useEffect, useRef, useState } from "react";
import Settings from "@assets/svgs/settingicon.svg";
import LeftArrow from "@assets/svgs/arrow_back.svg";
import ViewAllNotificationDrawer from "./ViewAllNotificationDrawer";
import ViewWhatsNewAllDrawer from "./ViewWhatsNewAllDrawer";
import ManageNotificationModal from "./ManageNotificationModal";
import WhatsNewCardComponent from "./WhatsNewCardComponent";
import NotificationCardComponent from "./NotificationCardComponent";
import { toast } from "react-toastify";
import { errorCode } from "../../../constants/common";
import { axiosGet } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { INotification } from "../../../Interface/notification.interface";
import BarLoader from "../../BarLoader";
import { useTranslation } from "react-i18next";
import SettingsIcon from "../../SvgColor/settingsIcon";
import ArrowBackIcon from "../../SvgColor/arrowBackIcon";

interface IProps {
    handleClose: () => void;
    notificationButtonRef: any;
}

const NotificationCard = ({ handleClose, notificationButtonRef }: IProps) => {
    const { t } = useTranslation();
    const drawerRef = useRef(null);

    const [currentTab, setCurrentTab] = useState<"notification" | "whatsNew">("notification");
    const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
    const [notificationsData, setNotificationsData] = useState<INotification[]>([]);

    const [isWhatsNewLoading, setIsWhatsNewLoading] = useState(false);

    const [isShowViewAllNotificationDrawer, setIsShowViewAllNotificationDrawer] = useState(false);
    const [isShowViewWhatsNewAllDrawer, setIsShowViewWhatsNewAllDrawer] = useState(false);
    const [isShowManageNotificationModal, setIsShowManageNotificationModal] = useState(false);

    useEffect(() => {
        loadNotification();
        loadWhatsNew();

        const handleClickOutside = (event: any) => {
            if (drawerRef.current && !((drawerRef.current as any).contains(event.target) || notificationButtonRef.current?.contains(event.target))) {
                handleClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const loadNotification = async () => {
        try {
            setIsNotificationsLoading(true);
            const response = await axiosGet(API.NOTIFICATION.USER_NOTIFICATION_CREATE_UPDATE, {
                rows: 3,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setNotificationsData(response.data.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsNotificationsLoading(false);
        }
    };

    const loadWhatsNew = () => {
        setIsWhatsNewLoading(true);
        setTimeout(() => {
            setIsWhatsNewLoading(false);
        }, 2000);
    };

    return (
        <div className="absolute right-5 md:right-[86px] z-20 top-16 w-[90%] md:w-[609px]" ref={drawerRef}>
            {!(isShowViewAllNotificationDrawer || isShowViewWhatsNewAllDrawer) && (
                <div className="bg-cardColor shadow-[0px_5.3px_15.9px_0px_#928C9740] rounded-3xl ">
                    <div className="px-6 pt-6 rounded-md">
                        <div className="flex items-center md:hidden justify-between mb-4 md:mb-0">
                            <span onClick={handleClose} className="block md:hidden">
                                <ArrowBackIcon fillColor="var(--icon-color)" />
                            </span>
                            <h1 className="text-center text-base md:text-[28px] lg:text-[28px] xl:text-[28px] md:mb-4 lg:mb-4 xl:mb-4  tracking-[-0.5px] text-txtcolor">{t("Notifications")}</h1>
                            <span onClick={() => setIsShowManageNotificationModal(true)} className="block md:hidden p-1 rounded-md cursor-pointer">
                                <SettingsIcon fillColor="var(--icon-color)" scale={0.75} />
                            </span>
                        </div>
                        <div className="flex items-center gap-4 mb-4">
                            <div className="flex flex-wrap sm:flex-nowrap gap-1 w-full bg-primary rounded-full justify-between">
                                <div className="m-1 text-center xl:w-full lg:w-full w-full sm:w-full">
                                    <span
                                        onClick={() => setCurrentTab("notification")}
                                        className={`tracking-[-0.2px] block cursor-pointer py-3 h-full font-medium text-sm rounded-full  ${
                                            currentTab === "notification" ? "bg-cardColor text-txtcolor" : "text-gray"
                                        }`}
                                    >
                                        {t("Notifications")}
                                    </span>
                                </div>
                                {/* <div className="m-1 text-center xl:w-[100%] lg:w-[100%] w-[46%] sm:w-[48%]">
                                    <span
                                        onClick={() => setCurrentTab("whatsNew")}
                                        className={`tarcking-[-0.2px] block cursor-pointer py-3 h-full  font-medium text-sm rounded-3xl   ${
                                            currentTab === "whatsNew" ? "bg-cardColor text-txtcolor" : "text-gray"
                                        }`}
                                    >
                                        {t("Whats New")}
                                    </span>
                                </div> */}
                            </div>

                            <span onClick={() => setIsShowManageNotificationModal(true)} className="hidden md:block p-1 border-2 md:border-0 border-primary rounded-md cursor-pointer">
                                <SettingsIcon fillColor="var(--icon-color)" />
                            </span>
                        </div>
                        <div className="flex mt-4 md:my-4 justify-end md:justify-between items-center">
                            <h1 className={`hidden md:block h-full tracking-[-0.5px] font-bold text-xl text-txtcolor`}>{currentTab === "notification" ? t("Notifications") : t("Whats New")}</h1>

                            <p
                                onClick={() => (currentTab === "whatsNew" ? setIsShowViewWhatsNewAllDrawer(true) : setIsShowViewAllNotificationDrawer(true))}
                                className="cursor-pointer text-sm md:text-base font-medium text-primary -tracking-[0.16px]"
                            >
                                {t("View All")}
                            </p>
                        </div>
                    </div>
                    {currentTab === "notification" && (
                        <div className="h-full">
                            {isNotificationsLoading ? (
                                <div className="min-h-[248px]">
                                    <div className="w-1/2 mt-2 mb-2">
                                        <BarLoader />
                                    </div>
                                </div>
                            ) : notificationsData.length ? (
                                notificationsData.map((notificationRecord: INotification) => (
                                    <NotificationCardComponent
                                        key={`${notificationRecord.name}_${notificationRecord.id}`}
                                        className="gap-3 rounded-l-lg flex h-full md:gap-6 p-[15px] md:p-6 items-center w-full border-b border-line"
                                        isNegative={false}
                                        notificationRecord={notificationRecord}
                                    />
                                ))
                            ) : (
                                <div className="min-h-[248px] justify-center items-center flex">No notifiaction</div>
                            )}
                        </div>
                    )}
                    {currentTab === "whatsNew" && (
                        <div className="p-3 md:p-6 pt-0 min-h-[200px] max-h-[480px] overflow-x-auto scrollbar-hide">
                            {isWhatsNewLoading ? (
                                <div className="min-h-[200px]">
                                    <div className="w-1/2 mt-2 mb-2">
                                        <BarLoader />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {Array(6)
                                        .fill(undefined)
                                        .map((items, index) => (
                                            <WhatsNewCardComponent key={index} className="mb-3" />
                                        ))}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}

            {isShowManageNotificationModal && <ManageNotificationModal handleClose={() => setIsShowManageNotificationModal(false)} />}

            <ViewAllNotificationDrawer isOpen={isShowViewAllNotificationDrawer} handleClose={() => setIsShowViewAllNotificationDrawer(false)} />

            <ViewWhatsNewAllDrawer isOpen={isShowViewWhatsNewAllDrawer} handleClose={() => setIsShowViewWhatsNewAllDrawer(false)} />
        </div>
    );
};

export default NotificationCard;
