import { EStripeCardType } from "../Interface";
import { LocalStorageKeys } from "../constants/keys";
import { persistor } from "../redux/store";
import moment from "moment";
import americanCardIcon from "@assets/img/cards/american.png";
import defaultCardIcon from "@assets/img/cards/credit-card.png";
import dinerCardIcon from "@assets/img/cards/diners.png";
import discoverCardIcon from "@assets/img/cards/discover.png";
import jcbCardIcon from "@assets/img/cards/jcb.png";
import masterCardIcon from "@assets/img/cards/mastercard.png";
import unionCardIcon from "@assets/img/cards/unionpay.png";
import visaCardIcon from "@assets/img/cards/visa.png";
import { addDays, getDay, startOfWeek } from "date-fns";

export const logout = async () => {
    await persistor.flush();
    await persistor.purge();
    await persistor.purge();
    localStorage.removeItem(LocalStorageKeys.authToken);
    window.location.href = `${window.location.origin}/`;
};

export const getInstagramUsernames = (url: string) => {
    const regex = /^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?(\w+)\/?$/;

    let resultName = "";

    let match = regex.exec(url);
    if (match) {
        resultName = match[1];
    }

    return resultName;
};

export const convertToHumanReadable = (durationString: string) => {
    // Parse the input duration string
    const [hours, minutes, seconds] = durationString.split(":").map(Number);

    // Create a moment duration
    const duration = moment.duration({ hours, minutes, seconds });

    // Check if there are hours and format accordingly
    let formattedDuration;
    if (duration.hours() > 0) {
        formattedDuration = moment.utc(duration.asMilliseconds()).format("H [hour] m [minute]");
    } else {
        formattedDuration = moment.utc(duration.asMilliseconds()).format("m [minute]");
    }

    // Check if hours and minutes are plural
    if (duration.hours() > 1) {
        formattedDuration = formattedDuration.replace("hour", "hours");
    }
    if (duration.minutes() > 1) {
        formattedDuration = formattedDuration.replace("minute", "minutes");
    }

    return formattedDuration;
};

export const CustomeLog = (logData: any) => {
    // console.log("============LogData===========", logData);
};

export const isOnAnyDay = (date: Date, desiredDays: string[]) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayIndex = date.getDay();
    const dayName = daysOfWeek[dayIndex];
    return desiredDays.includes(dayName.toLocaleLowerCase());
};

export const getOrdinalSuffix = (d: string) => {
    const day = Number(d);

    if (day >= 11 && day <= 13) {
        return "th";
    }
    switch (day % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};

export const getCardTypeImage = (cardType: string) => {
    switch (cardType?.toUpperCase()) {
        case EStripeCardType.VISA.toUpperCase():
            return visaCardIcon;
        case EStripeCardType.MASTER.toUpperCase():
            return masterCardIcon;
        case EStripeCardType.AMERICAN.toUpperCase():
            return americanCardIcon;
        case EStripeCardType.DINER.toUpperCase():
            return dinerCardIcon;
        case EStripeCardType.DISCOVER.toUpperCase():
            return discoverCardIcon;
        case EStripeCardType.JCB.toUpperCase():
            return jcbCardIcon;
        case EStripeCardType.UNION.toUpperCase():
            return unionCardIcon;
        default:
            return defaultCardIcon;
    }
};

export const updateMetaThemeColor = (color: string) => {
    // Remove the current meta
    document.querySelector('meta[name="theme-color"]')?.remove();

    // Add the new one
    var newMeta = document.createElement("meta");
    newMeta.setAttribute("name", "theme-color");
    newMeta.setAttribute("content", color);
    document.querySelector("head")?.appendChild(newMeta);
};

export const getNextDayOfWeek = (dateString: Date, dayOfWeek: string) => {
    const targetDay = dayOfWeek.toLowerCase();
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    let daysUntilTarget = (getDay(startOfWeek(dateString)) + 7 - getDay(dateString) + days.indexOf(targetDay)) % 7;
    return addDays(dateString, daysUntilTarget);
};

export const getNextAvailableDay = (selectedDay: string, workingDays: string[]) => {
    // Define an array to map day names to their numerical representation
    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    // Convert the specific day 'selectedDay' to its numerical representation
    const xIndex = daysOfWeek.indexOf(selectedDay.toLowerCase());

    if (xIndex === -1) {
        // Return an error if the specific day is not found in the array
        return false;
    }

    // Convert the day names in array 'workingDays' to their numerical representation
    const yIndices = workingDays.map((day) => daysOfWeek.indexOf(day.toLowerCase())).filter((index) => index !== -1);

    // Find the next available day from array 'workingDays' that is greater than or equal to 'selectedDay'
    let nextDayIndex = yIndices.find((index) => index > xIndex);

    if (nextDayIndex === undefined) {
        // If no day is found, return the first day in 'workingDays'
        nextDayIndex = yIndices[0];
    }

    // Convert the numerical representation of the next day back to its name
    return daysOfWeek[nextDayIndex];
};

export const calculateTotalTaxes = (subTotal: string, hst: number, gst: number, pst: number) => {
    const totalTaxPercentage = hst + pst + gst;
    const subTotalNumber = parseFloat(subTotal.replace(/[^0-9.-]+/g, ""));
    const taxDecimal = totalTaxPercentage / 100;
    return (subTotalNumber * taxDecimal).toFixed(2);
};
