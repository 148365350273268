import React, { useState } from "react";
import CommonModal from "../../../CommonModal";
import ButtonCmp from "../../../ButtonCmp";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { axiosPatch } from "../../../../utils/requestClient";
import { API } from "../../../../constants/api";
import { errorCode } from "../../../../constants/common";
import { toast } from "react-toastify";
import { userMe } from "../../../../redux/reducers/Login.slice";
import { useTranslation } from "react-i18next";

interface IProps {
    handleClose: () => void;
}

const ManageNotificationModal = ({ handleClose }: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const authUserState = useAppSelector((data) => data.AuthUser);
    const [promotions, setPromotions] = useState(authUserState.user?.notification.promotions);
    const [bookingConfirm, setBookingConfirm] = useState(authUserState.user?.notification.booking_confirm);
    const [bookingReschedule, setBookingReschedule] = useState(authUserState.user?.notification.booking_reschedule);
    const [bookingCanceled, setBookingCanceled] = useState(authUserState.user?.notification.booking_canceled);
    const [whatsNew, setWhatsNew] = useState(authUserState.user?.notification.whats_new);
    const [referral, setReferral] = useState(authUserState.user?.notification.referral);
    const [isNotificationSettingUpdating, setIsNotificationSettingUpdating] = useState(false);

    const handleSubmit = async () => {
        try {
            setIsNotificationSettingUpdating(true);
            const response = await axiosPatch(API.NOTIFICATION.USER_NOTIFICATION_CREATE_UPDATE, {
                booking_confirm: bookingConfirm,
                booking_canceled: bookingCanceled,
                booking_reschedule: bookingReschedule,
                whats_new: whatsNew,
                promotions: promotions,
                referral: referral,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(userMe({}));
                toast.success(response.data.message);
                handleClose();
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsNotificationSettingUpdating(false);
        }
    };

    return (
        <CommonModal handleClose={handleClose} size="max-w-[600px]" isClosable={!isNotificationSettingUpdating} bodyClass="md:pt-8 md:pb-10">
            <div className="text-center">
                <h1 className="tracking-[-0.5px] text-xl leading-[140%] text-txtcolor font-bold mb-4">{t("Manage Notifications")}</h1>
            </div>
            <div className="w-full">
                <div className="flex justify-between py-4 border-b">
                    <h1 className="text-txtcolor font-medium text-sm md:text-base">{t("Promotions")}</h1>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" disabled={isNotificationSettingUpdating} checked={promotions} onChange={(e) => setPromotions(e.target.checked)} className="sr-only peer" />
                        <div className="w-[50px] h-[26px] bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[22px] after:w-[23px] after:transition-all  peer-checked:bg-primary"></div>
                    </label>
                </div>
                <div className="flex justify-between py-4 border-b">
                    <h1 className="text-txtcolor font-medium text-sm md:text-base">{t("Confirmed Appointments")}</h1>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" disabled={isNotificationSettingUpdating} checked={bookingConfirm} onChange={(e) => setBookingConfirm(e.target.checked)} className="sr-only peer" />
                        <div className="w-[50px] h-[26px] bg-gray-200 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[22px] after:w-[23px] after:transition-all  peer-checked:bg-primary"></div>
                    </label>
                </div>
                <div className="flex justify-between py-4 border-b">
                    <h1 className="text-txtcolor font-medium text-sm md:text-base">{t("Rescheduled Appointments")}</h1>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" disabled={isNotificationSettingUpdating} checked={bookingReschedule} onChange={(e) => setBookingReschedule(e.target.checked)} className="sr-only peer" />
                        <div className="w-[50px] h-[26px] bg-gray-200 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[22px] after:w-[23px] after:transition-all  peer-checked:bg-primary"></div>
                    </label>
                </div>
                <div className="flex justify-between py-4 border-b">
                    <h1 className="text-txtcolor font-medium text-sm md:text-base">{t("Cancelled Appointments")}</h1>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" disabled={isNotificationSettingUpdating} checked={bookingCanceled} onChange={(e) => setBookingCanceled(e.target.checked)} className="sr-only peer" />
                        <div className="w-[50px] h-[26px] bg-gray-200 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[22px] after:w-[23px] after:transition-all  peer-checked:bg-primary"></div>
                    </label>
                </div>
                <div className="flex justify-between py-4 border-b">
                    <h1 className="text-txtcolor font-medium text-sm md:text-base">{t("What's New")}</h1>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" disabled={isNotificationSettingUpdating} checked={whatsNew} onChange={(e) => setWhatsNew(e.target.checked)} className="sr-only peer" />
                        <div className="w-[50px] h-[26px] bg-gray-200 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[22px] after:w-[23px] after:transition-all  peer-checked:bg-primary"></div>
                    </label>
                </div>
                <div className="flex justify-between py-4">
                    <h1 className="text-txtcolor font-medium text-sm md:text-base">{t("Referral Program")}</h1>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" disabled={isNotificationSettingUpdating} checked={referral} onChange={(e) => setReferral(e.target.checked)} className="sr-only peer" />
                        <div className="w-[50px] h-[26px] bg-gray-200 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[22px] after:w-[23px] after:transition-all  peer-checked:bg-primary"></div>
                    </label>
                </div>
            </div>
            <div className="pt-4">
                <ButtonCmp className="btn_primary fl-btn w-full" onClick={handleSubmit} loading={isNotificationSettingUpdating}>
                    {t("Save")}
                </ButtonCmp>
            </div>
        </CommonModal>
    );
};

export default ManageNotificationModal;
