import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const CircularProgressWithLabel: React.FC<any> = (props) => (
    <div style={{ position: "relative", display: "inline-flex" }}>
        <CircularProgressbar
            variant="determinate"
            {...props}
            styles={buildStyles({
                textSize: "16px",
                pathColor: "#1642C5",
                textColor: "#1F293C",
                trailColor: "#E3E7EF",
                backgroundColor: "#f00000",
            })}
        />
        <div
            style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <p>{`${Math.round(props.value)}%`}</p>
        </div>
    </div>
);

export default CircularProgressWithLabel;
