import React, { useState } from "react";
import Pic from "@assets/img/categoryImg/Rectangle 926.png";
import WhatsNewDetailsModal from "../WhatsNewDetailsModal";

interface IProps {
    className: string;
}

const WhatsNewCardComponent = ({ className }: IProps) => {
    const [isShowWhatsNewModal, setIsShowWhatsNewModal] = useState(false);

    return (
        <>
            <div onClick={() => setIsShowWhatsNewModal(true)} className={`${className} md:h-24 flex gap-4 items-center rounded-3xl shadow-[0px_2px_33px_0px_rgba(0,0,0,0.05)] `}>
                <img src={Pic} className="h-[120px] md:h-full rounded-l-3xl w-[93px] object-cover" alt="" />
                <div className="w-5/6 p-3 ps-0 md:p-0 md:pr-3">
                    <div className="flex justify-between mb-[5px] md:mb-2">
                        <h1 className="text-txtcolor text-sm font-bold">Lorem ipsum dolor</h1>
                        <p className="text-secondaryTxt text-xs font-normal">30m</p>
                    </div>
                    <div className="w-[90%]">
                        <p className="text-secondaryTxt text-xs font-normal leading-[18px]">
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim
                        </p>
                    </div>
                </div>
            </div>
            {isShowWhatsNewModal && <WhatsNewDetailsModal setIsShowWhatsNewModal={setIsShowWhatsNewModal} />}
        </>
    );
};

export default WhatsNewCardComponent;
