import React, { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import InputWithLabel from "../../../components/InputWithLabel";
import ButtonCmp from "../../../components/ButtonCmp";
import { HiEye } from "react-icons/hi2";
import { HiEyeOff } from "react-icons/hi";
import { toast } from "react-toastify";
import { axiosPost } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX, errorCode } from "../../../constants/common";
import { useTranslation } from "react-i18next";
interface ISetPasswordFormProps {
    password: string;
    password_confirmation: string;
}

interface IProps {
    handleNextStep: () => void;
}

const SetPassword = ({ handleNextStep }: IProps) => {
    const { t } = useTranslation();
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
    const [isSetNewPasswordLoading, setIsSetNewPasswordLoading] = useState(false);

    const schema = Yup.object({
        password: Yup.string()
            .required(t("Please provide a password"))
            .min(6, t("Password must be at least 6 characters"))
            .trim()
            .matches(COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX, t("Include at least one uppercase, lowercase and either a digit or one of these special characters: !@#$%^&*_|")),

        password_confirmation: Yup.string()
            .required(t("Please confirm a password"))
            .oneOf([Yup.ref("password"), null], t("Passwords do not match")),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { isValid, errors },
        setError,
    } = useForm<ISetPasswordFormProps>({
        resolver: yupResolver(schema),
        defaultValues: {
            password: "",
            password_confirmation: "",
        },
    });

    const HandleSetNewPassword = async (data: ISetPasswordFormProps) => {
        if (isValid) {
            try {
                setIsSetNewPasswordLoading(true);
                const response = await axiosPost(API.USER.SET_NEW_PASSWORD, {
                    password: data.password,
                    password_confirmation: data.password_confirmation,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    handleNextStep();
                    return;
                }

                if (response.data?.status === errorCode.unprocessable && response.data.data) {
                    Object.keys(response.data.data).forEach((field) => {
                        setError(field as any, {
                            type: "manual",
                            message: response.data.data[field][0],
                        });
                    });
                    return;
                }
                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsSetNewPasswordLoading(false);
            }
        }
    };

    return (
        <div className="content">
            <h1 className="text-xl md:text-[32px] -tracking-[0.24px] md:-tracking-[0.384px] leading-6 md:leading-10 font-semibold mb-4 text-txtcolor">{t("Set up your password")}</h1>
            <p className=" text-secondaryTxt opacity-7 tracking-[-0.01em] text-[14px] font-[400] mb-[30px] md:mb-10">{t("Enter your personal password for further logins")}</p>
            <form action="" onSubmit={handleSubmit(HandleSetNewPassword)}>
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div className="mb-5">
                            <InputWithLabel
                                handleChange={onChange}
                                name="password"
                                value={value}
                                placeholder="**********"
                                label={t("New Password")}
                                buttonIcon={isShowPassword ? <HiEyeOff size={18} color="#8C9CBB" /> : <HiEye size={18} color="#8C9CBB" />}
                                handlerIconClick={() => setIsShowPassword(!isShowPassword)}
                                type={`${isShowPassword ? "text" : "password"}`}
                                disabled={isSetNewPasswordLoading}
                                inputClass={`${errors.password ? "is-invalid border-solid" : ""}`}
                            />
                            <p className="text-red-500">{errors.password && errors.password.message}</p>
                        </div>
                    )}
                />
                <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div className="mb-5">
                            <InputWithLabel
                                handleChange={onChange}
                                name="password_confirmation"
                                value={value}
                                placeholder="**********"
                                label={t("Confirm Password")}
                                buttonIcon={isShowConfirmPassword ? <HiEyeOff size={18} color="#8C9CBB" /> : <HiEye size={18} color="#8C9CBB" />}
                                handlerIconClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                                type={`${isShowConfirmPassword ? "text" : "password"}`}
                                disabled={isSetNewPasswordLoading}
                                inputClass={`${errors.password_confirmation ? "is-invalid border-solid" : ""}`}
                            />
                            <p className="text-red-500">{errors.password_confirmation && errors.password_confirmation.message}</p>
                        </div>
                    )}
                />
                <ButtonCmp type="submit" className="btn_primary fl-btn w-full mt-5" disabled={isSetNewPasswordLoading} loading={isSetNewPasswordLoading}>
                    {t("Confirm")}
                </ButtonCmp>
            </form>
        </div>
    );
};

export default SetPassword;
