import React, { useState } from "react";
import addImage from "@assets/img/add-image.svg";
import { MAX_IMAGE_SIZE_ALLOWED } from "../../../../constants/common";
import CircularProgressWithLabel from "../../../../components/CircularProgressWithLabel";
import { BsCloudUpload } from "react-icons/bs";
import AWS from "aws-sdk";
import { useTranslation } from "react-i18next";

const REACT_APP_AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
const REACT_APP_AWS_SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY;
const REACT_APP_AWS_REGION = process.env.REACT_APP_AWS_REGION;
const REACT_APP_AWS_S3_BUCKET_NAME = process.env.REACT_APP_AWS_S3_BUCKET_NAME;

AWS.config.update({
    accessKeyId: REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: REACT_APP_AWS_SECRET_KEY,
});

const flairMediaS3Bucket = new AWS.S3({
    params: { Bucket: REACT_APP_AWS_S3_BUCKET_NAME },
    region: REACT_APP_AWS_REGION,
});

interface IProps {
    onPictureChange: (e: any) => void;
    pictureName: string | undefined;
    isInvalid: boolean;
    disabled: boolean;
    error: string | undefined;
    setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const UploadComponent = ({ onPictureChange, pictureName, isInvalid, disabled, error, setError }: IProps) => {
    const { t } = useTranslation();
    const [isUploadingPercentage, setIsUploadingPercentage] = useState<number | undefined>(undefined);

    const handleRetry = () => {
        setError("");
        setIsUploadingPercentage(undefined);
    };

    const onChange = (event: any) => {
        const image = event.target.files[0];
        if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
            setError("select valid image.");
            return false;
        }
        if (image.size >= MAX_IMAGE_SIZE_ALLOWED) {
            setError(`Image file size exceeds the allowed limit. Please choose a smaller file (max size: [800KB]).`);
            return false;
        }

        uploadImage(image);
    };

    const uploadImage = (image: any) => {
        try {
            setIsUploadingPercentage(0);

            const random10DigitNumber = Math.floor(100000000 + Math.random() * 900000000);
            const currentTimeStamp = Date.now();
            const imageExtension = image.name.split(".").pop();
            const generatedImageName = `${currentTimeStamp}_${random10DigitNumber}.${imageExtension}`;

            const params = {
                Body: image,
                Bucket: REACT_APP_AWS_S3_BUCKET_NAME!,
                Key: `images/user_profile/${generatedImageName}`,
            };

            flairMediaS3Bucket
                .putObject(params)
                .on("httpUploadProgress", (evt) => {
                    setIsUploadingPercentage(Math.round((evt.loaded / evt.total) * 100));
                })
                .promise()
                .then(
                    (data) => {
                        setIsUploadingPercentage(undefined);
                        onPictureChange(generatedImageName);
                        return data;
                    },
                    (err) => {
                        throw err;
                    },
                );
        } catch (e) {
            setIsUploadingPercentage(undefined);
            setError("Please check your internet connection and try again.");
        }
    };

    return (
        <>
            {error && (
                <>
                    <label
                        className={`w-[100px] md:w-full h-full flex justify-center items-center flex-col rounded-3xl cursor-pointer is-invalid border-2 border-dashed border-red-500`}
                        onClick={handleRetry}
                    >
                        <img src={addImage} alt="" className="w-[30px] md:w-[40px]" />
                        <h2 className="text-primary text-[11px] md:text-sm font-bold -tracking-[0.14px]">{t("Retry")}</h2>
                    </label>
                </>
            )}

            {!error && isUploadingPercentage === undefined && !pictureName && (
                <>
                    <label
                        htmlFor="upload-profile-picture"
                        className={`w-[100px] md:w-full h-full flex justify-center items-center flex-col rounded-3xl cursor-pointer ${
                            isInvalid ? "is-invalid border-2 border-dashed border-red-500" : "bg-inputbg"
                        }`}
                    >
                        <img src={addImage} alt="" className="w-[30px] md:w-[40px]" />
                        <h2 className="text-txtcolor text-[11px] md:text-sm font-bold -tracking-[0.14px]">{t("Your image")}</h2>
                        <p className="text-secondaryTxt text-[10px] md:text-xs font-medium -tracking-[0.12px] mt-1">{t("JPG or PNG.")}</p>
                        <p className="text-secondaryTxt text-[10px] md:text-xs font-medium -tracking-[0.12px]">{t("Max size of 800K")}</p>
                    </label>
                    <input className="opacity-0 absolute z-[-1]" id="upload-profile-picture" type="file" onChange={onChange} disabled={disabled} />
                </>
            )}

            {isUploadingPercentage !== undefined && (
                <label className={`w-[100px] md:w-full h-full flex justify-center items-center flex-col rounded-3xl cursor-pointer bg-inputbg`} onClick={handleRetry}>
                    <CircularProgressWithLabel value={isUploadingPercentage} className="w-[63px] h-[63px]" Z />
                </label>
            )}

            {pictureName && isUploadingPercentage === undefined && !error && (
                <>
                    <label
                        htmlFor="reupload-profile-picture"
                        className={`w-full h-full relative flex justify-center items-center flex-col rounded-3xl bg-inputbg overflow-hidden`}
                        onClick={handleRetry}
                    >
                        <img
                            className="w-[100px] md:w-full h-full object-cover"
                            src={`https://media.getflair.ca/images/user_profile/${pictureName}`} // TODO : get rid of this static url
                            alt=""
                        />
                        <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-inputbg w-10 h-10 md:w-[60px] md:h-[60px] rounded-full text-primary flex justify-center items-center">
                            <BsCloudUpload size={20} />
                        </div>
                    </label>
                    <input className="opacity-0 absolute z-[-1]" id="reupload-profile-picture" type="file" onChange={onChange} disabled={disabled} />
                </>
            )}
        </>
    );
};

export default UploadComponent;
