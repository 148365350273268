import { ComponentType, LazyExoticComponent } from "react";

export enum ERouteType {
    GUARDED = "GUARDED",
    NOT_GUARDED = "NOT_GUARDED",
    DEFAULT = "DEFAULT",
}

export type RouteItem = {
    type: ERouteType;
    path: string;
    component: LazyExoticComponent<ComponentType>;
    title?: string;
};

export const somethingWentWrongError = "We are sorry, but something went wrong. Please try again later.";

export const errorCode = {
    notFound: 404,
    success: 200,
    updateSuccess: 201,
    unprocessable: 422,
    internalError: 500,
};

export const MAX_IMAGE_SIZE_ALLOWED = 800000;

export const COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*_|]).{6,}$/;

export const GENDER_OPTIONS = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "non-binary", label: "Non-binary" },
    { value: "prefer not to respond", label: "Prefer not to respond" },
];
