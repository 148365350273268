import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileIcon } from "../../SvgColor/svgIcons";
import { ReactComponent as Logout } from "@assets/svgs/Layer_35.svg";
import { FaRegCopy } from "react-icons/fa6";
import { LuCopyCheck } from "react-icons/lu";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ConfirmationModal from "../../ConfirmationModal";
import { userLogout } from "../../../redux/reducers/Login.slice";
import { logout } from "../../../utils/global-functions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface IProps {
    handleClose: () => void;
    profileButtonRef: any;
}

const ProfileMenuComponent = ({ handleClose, profileButtonRef }: IProps) => {
    const { t } = useTranslation();
    const [referralConfig, setReferralConfig] = useState({ refferer: 0, refferer_type: "percentage" });
    const [isCopyIcon, setIsCopyIcon] = useState(false);
    const popupRef = useRef(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authUserState = useAppSelector((data) => data.AuthUser);
    const [isShowLogoutConfirmationModal, setIsShowLogoutConfirmationModal] = useState(false);
    const logoutHandler = async () => {
        const result = await dispatch(userLogout({}));

        if (result.type === userLogout.fulfilled.toString()) {
            logout();
            setIsShowLogoutConfirmationModal(false);
        }

        if (result.type === userLogout.rejected.toString()) {
            const response = result.payload.data;
            toast.error(response.message);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popupRef.current && !((popupRef.current as any).contains(event.target) || profileButtonRef.current?.contains(event.target))) {
                handleClose();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const filterShopSetting = authUserState.shopData?.shop_settings?.find((shop: { type: string }) => shop.type === "referral_config");
        if (filterShopSetting) {
            setReferralConfig({
                refferer: filterShopSetting.value?.refferer || 0,
                refferer_type: filterShopSetting.value?.refferer_type || "",
            });
        } else {
            setReferralConfig({
                refferer: 0,
                refferer_type: "",
            });
        }
    }, [authUserState.shopData?.shop_settings]);

    const referralCodeCopied = () => {
        if (authUserState.user?.referral_code) {
            const textField = document.createElement("textarea");
            textField.innerText = authUserState.user.referral_code;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand("copy");
            document.body.removeChild(textField);
            setIsCopyIcon(true);
            setTimeout(() => {
                setIsCopyIcon(false);
            }, 1000);
        }
    };
    const handleNavigateToProfile = () => {
        console.log("Enter in navigate");
        navigate("/profile-setting");
        handleClose();
    };
    return (
        <div className="bg-cardColor rounded-3xl shadow-[0px_5.333px_16px_0px_rgba(146,140,151,0.25);] w-[293px]" ref={popupRef}>
            <div className="w-full">
                {authUserState.user?.referral_code && (
                    <div className="flex flex-col p-5">
                        <label className="font-bold text-txtcolor text-xs mb-2 leading-[140%] -tracking-[0.12px]">{t("Your Referral code")}</label>
                        <div
                            className="text-txtcolor font-bold text-center leading-[140%] -tracking-[0.12px] border-dashed border-[#98A7C3] border-2 text-sm px-4 py-3 rounded-2xl cursor-pointer flex items-center justify-center"
                            onClick={referralCodeCopied}
                        >
                            {authUserState.user?.referral_code}
                            <span className="ms-3">{isCopyIcon ? <LuCopyCheck /> : <FaRegCopy />}</span>
                        </div>
                        <span className="text-txtcolor tracking-[0.025px] font-normal leading-4 mt-1 text-[10px] ">
                            {t("Receive")} <strong>{referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`}</strong>{" "}
                            {t("off any service when you refer a friend!")}
                        </span>
                    </div>
                )}
                <hr className="text-lightgray" />
                <div onClick={() => handleNavigateToProfile()} className="py-5 px-6 flex gap-2 cursor-pointer">
                    <span>
                        <ProfileIcon fillColor="var(--icon-color)" />
                    </span>
                    <span className="text-txtcolor cursor-pointer text-sm font-normal">Profile</span>
                </div>
                <hr className="text-lightgray" />
                <div onClick={() => setIsShowLogoutConfirmationModal(true)} className="py-5 px-6 flex gap-2 cursor-pointer">
                    <span>
                        <Logout />
                    </span>
                    <span className="text-txtcolor cursor-pointer text-sm font-normal">{t("Logout")}</span>
                </div>
            </div>
            {isShowLogoutConfirmationModal && (
                <ConfirmationModal
                    handleClose={() => setIsShowLogoutConfirmationModal(false)}
                    title={t("Logout")}
                    description={t("Are you sure you want to logout from your account?")}
                    buttonSuccessText={t("Yes, I am sure")}
                    buttonCancelText={t("Not now")}
                    successHandler={logoutHandler}
                    isSuccessLoading={authUserState.loading}
                />
            )}
        </div>
    );
};

export default ProfileMenuComponent;
