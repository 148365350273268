import React, { useState } from "react";
import { EAuthFlowType, EForgetPasswordStep } from "./Login.interface";
import LoginWithPhoneController from "./LoginWithPhoneController";
import LoginWithEmailController from "./LoginWithEmailController";
import ForgetPasswordController from "./ForgetPasswordController";

interface IProps {
    onCloseModal: () => void;
    loginStep?: EForgetPasswordStep;
    setLoginStep?: React.Dispatch<React.SetStateAction<EForgetPasswordStep>>;
}

const Login = ({ onCloseModal, loginStep, setLoginStep }: IProps) => {
    const [currentFlow, setCurrentFlow] = useState<EAuthFlowType>(EAuthFlowType.PHONE);

    return (
        <>
            {currentFlow === EAuthFlowType.PHONE && <LoginWithPhoneController handlerLoginWithEmail={() => setCurrentFlow(EAuthFlowType.EMAIL)} onCloseModal={onCloseModal} />}
            {currentFlow === EAuthFlowType.EMAIL && (
                <LoginWithEmailController
                    handlerLoginWithPhone={() => setCurrentFlow(EAuthFlowType.PHONE)}
                    handlerForgetPassword={() => setCurrentFlow(EAuthFlowType.FORGET_PASSWORD)}
                    onCloseModal={onCloseModal}
                />
            )}
            {currentFlow === EAuthFlowType.FORGET_PASSWORD && <ForgetPasswordController onCloseModal={onCloseModal} loginStep={loginStep} setLoginStep={setLoginStep} />}
        </>
    );
};

export default Login;
