import React, { useState } from "react";
import CommonModal from "../../../components/CommonModal";
import { EForgetPasswordStep } from "../Login.interface";
import Forgotpassword from "./Forgotpassword";
import Otp from "../../../components/Otp";
import SetPassword from "./SetPassword";
import { useAppSelector } from "../../../redux/hooks";

interface IProps {
    onCloseModal: () => void;
    loginStep?: EForgetPasswordStep;
    setLoginStep?: React.Dispatch<React.SetStateAction<EForgetPasswordStep>>;
}

const ForgetPasswordController = ({ onCloseModal, loginStep, setLoginStep }: IProps) => {
    const AuthUserState = useAppSelector((data) => data.AuthUser);

    const [email, setEmail] = useState("");

    return (
        <CommonModal size="max-w-[600px]" handleClose={onCloseModal} bodyClass="md:pt-10 md:pb-10">
            {loginStep === EForgetPasswordStep.FORGET_PASSWORD && <Forgotpassword handleNextStep={() => setLoginStep?.(EForgetPasswordStep.OTP)} setEmail={setEmail} />}
            {loginStep === EForgetPasswordStep.OTP && (
                <Otp
                    handleNextStep={() => {
                        if (!AuthUserState.user?.is_user_verified) {
                            setLoginStep?.(EForgetPasswordStep.SET_PASSWORD);
                        }
                    }}
                    email={email}
                />
            )}
            {loginStep === EForgetPasswordStep.SET_PASSWORD && <SetPassword handleNextStep={() => onCloseModal()} />}
        </CommonModal>
    );
};

export default ForgetPasswordController;
