import { AsYouType, CountryCode, parsePhoneNumber } from "libphonenumber-js";

export const usePhone = () => {
    const setFormatPhone = (code: string, phone: string, regionCode: CountryCode) => {
        try {
            if (phone) {
                const number = parsePhoneNumber(phone, regionCode);
                return number.isValid() ? number.format("NATIONAL", { nationalPrefix: false }) : new AsYouType(regionCode).input(phone);
            }
            return new AsYouType(regionCode).input(phone);
        } catch {
            return new AsYouType(regionCode).input(phone);
        }
    };

    const isPhoneValid = (phone: string, regionCode: CountryCode): boolean => {
        try {
            const number = parsePhoneNumber(phone, regionCode);

            return number.isValid();
        } catch {
            return false;
        }
    };
    const phoneInfo = (phone: string, regionCode: CountryCode) => {
        try {
            return parsePhoneNumber(phone, regionCode);
        } catch {
            return false;
        }
    };

    return { setFormatPhone, isPhoneValid, phoneInfo };
};
