import React from "react";
import { FaCheck } from "react-icons/fa6";
import { ActionMeta, components } from "react-select";
import CreatableSelect from "react-select/creatable";
const { Option } = components;

interface IProps {
    options: any[];
    allowClear: boolean;
    disabled: boolean;
    name: string;
    label?: string;
    defaultValue?: string;
    searchable?: boolean;
    onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
    value: any | undefined;
    placeholder?: string;
    openMenuOnClick?: boolean;
    inputClass?: string;
}

const SelectBox = ({ label, options, allowClear, disabled, name, defaultValue, searchable = false, onChange, value, placeholder, openMenuOnClick = true, inputClass }: IProps) => (
    <>
        {label && <label className="fl-field-title-label">{label}</label>}
        <CreatableSelect
            className={`flr_select_box ${inputClass}`}
            classNamePrefix="select"
            defaultValue={defaultValue}
            isDisabled={disabled}
            isClearable={allowClear}
            isSearchable={searchable}
            // menuIsOpen
            name={name}
            options={options}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            openMenuOnClick={openMenuOnClick}
            components={{ Option: IconOption }}
        />
    </>
);

const IconOption = (props: any) => (
    <Option {...props}>
        <div className="flex justify-between items-center font-medium ">
            {props.data.label}
            {props.isSelected && <FaCheck size={16} />}
        </div>
    </Option>
);

export default SelectBox;
