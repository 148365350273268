export const APP_ROOT = "";

export const AUTH_ROUTES = {
    LOGIN: `${APP_ROOT}/login`,
};

export const ROUTES = {
    HOME: `${APP_ROOT}/`,
    MOBILE_LANDING: `${APP_ROOT}/mobile-landing`,
    BOOK_APPOINTMENT: `${APP_ROOT}/appointment`,
    PROFILE_SETTING: `${APP_ROOT}/profile-setting`,
    PAGE_NOT_FOUND: `${APP_ROOT}/not-found`,
};
