import React, { useEffect } from "react";
import { RouteProps, Navigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { useAppSelector } from "../redux/hooks";

type GuardedRouteProps = {
    Component: React.ComponentType<any>;
    title?: string;
} & RouteProps;

const GuardedRoute: React.FC<GuardedRouteProps> = ({ Component, title, ...routeProps }): JSX.Element => {
    const authUserState = useAppSelector((data) => data.AuthUser);

    useEffect(() => {
        document.title = `${title} - ${authUserState.globalShop?.label ?? "FLAIR"}`;
    }, [title, authUserState.globalShop]);

    return authUserState.isAuthenticated ? <Component /> : <Navigate to={ROUTES.HOME} />;
};

export default GuardedRoute;
