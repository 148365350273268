export const API = {
    USER: {
        USER_ME: "/user/shop/{shop_id}/me",
        LOGIN_WITH_EMAIL: "/user/login",
        LOGIN_WITH_PHONE: "/user/login/phone",
        LOGIN_WITH_PHONE_OTP_VERIFY: "/user/login/verify-otp",
        FORGET_PASSWORD_SEND_OTP: "/user/forgot-password",
        FORGET_PASSWORD_VERIFY_OTP: "/user/forgot-password/verify-otp",
        SET_NEW_PASSWORD: "/user/new-password",
        UPDATE_USER_ACCOUNT: "/user/shop/{shop_id}/account",
        USER_LOGOUT: "/user/logout",
        USER_PROFILE_IMAGE_UPDATE: "/user/image",
        USER_PROFILE_PROFILE_UPDATE: "/user/profile",
        USER_PROFILE_EMAIL_UPDATE: "/user/email",
        USER_PROFILE_EMAIL_UPDATE_WITH_OTP_VERIFY: "/user/email/verify-otp",
        USER_PROFILE_PHONE_UPDATE: "/user/phone",
        USER_PROFILE_PHONE_UPDATE_WITH_OTP_VERIFY: "/user/phone/verify-otp",
        USER_CHANGE_PASSWORD: "/user/change-password",
    },
    LOCATION: {
        SHOP_WITH_LOCATION_AND_SEARCH: "/user/shop/{shop_id}/location",
    },
    STAFF: {
        USER_SHOP_LOCATION_STAFF: "/user/shop/{shop_id}/location/{location_id}/staff",
    },
    STAFF_ROLE: {
        USER_SHOP_LOCATION_STAFF_ROLE: "/user/shop/{shop_id}/staff/role",
    },
    CATEGORY: {
        USER_SHOP_LOCATION_CATEGORY: "/user/shop/{shop_id}/location/{location_id}/category",
    },
    SERVICE: {
        USER_SHOP_LOCATION_SERVICE: "/user/shop/{shop_id}/location/{location_id}/service",
    },
    BOOKING: {
        USER_SHOP_LOCATION_TIME_SLOTS: "/user/shop/{shop_id}/location/{location_id}/slot",
        USER_SHOP_LOCATION_CREATE_BOOKING: "/user/shop/{shop_id}/location/{location_id}/booking",
        USER_SHOP_LOCATION_UPDATE_BOOKING: "/user/shop/{shop_id}/location/{location_id}/booking/{id}",
        USER_SHOP_LOCATION_BOOKING_LIST: "/user/shop/{shop_id}/booking",
        USER_SHOP_LOCATION_BOOKING_UPDATE_STATUS: "/user/shop/{shop_id}/booking/{id}/status",
        USER_SHOP_LOCATION_BOOKING_TIP: "/user/shop/{shop_id}/booking/{id}/tip",
        USER_SHOP_LOCATION_BOOKING_RECEIPT: "/user/shop/{shop_id}/booking/{id}/receipt",
        USER_SHOP_LOCATION_BOOKING_REVIEW: "/user/shop/{shop_id}/booking/{id}/review",
    },
    STRIPE: {
        USER_STRIPE_CARD_LIST: "/user/stripe/card/list",
        USER_STRIPE_CARD_ADD: "/user/stripe/card/add",
        USER_STRIPE_CARD_DEFAULT: "/user/stripe/card/default",
        USER_STRIPE_CARD_REMOVE: "/user/stripe/card/remove",
    },
    NOTIFICATION: {
        USER_NOTIFICATION_CREATE_UPDATE: "/user/notification",
    },
    PROMOTION: {
        USER_PROMOTION: "/user/shop/{shop_id}/promotion",
    },
    USER_SHOP: {
        USER_SHOP_GET: "/user/shop/{shop_id}",
    },
};
