import React, { FC, useEffect, useRef, useState } from "react";
import "./otp.css";
import { useTranslation } from "react-i18next";

interface Props {
    otp: string[];
    setOtp: React.Dispatch<React.SetStateAction<string[]>>;
    disabled?: boolean;
    error?: string | undefined;
}
let currentOTPIndex: number = 0;
const OTPField: FC<Props> = ({ otp, setOtp, disabled = false, error }): JSX.Element => {
    const { t } = useTranslation();
    const [activeOTPIndex, setactiveOTPIndex] = useState<number>(0);

    const handlerOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = target;
        const newOTP: string[] = [...otp];
        newOTP[currentOTPIndex] = value.substring(value.length - 1);
        if (!value) {
            setactiveOTPIndex(currentOTPIndex - 1);
        } else {
            setactiveOTPIndex(currentOTPIndex + 1);
        }
        setOtp(newOTP);
    };

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, [activeOTPIndex]);

    const handlerKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        currentOTPIndex = index;
        if (key === "Backspace") {
            setactiveOTPIndex(currentOTPIndex - 1);
        }
    };

    return (
        <div className="text-center">
            <div className="flex justify-center space-x-4">
                {otp.map((otpNumber: string, index: number) => (
                    <React.Fragment key={index}>
                        <input
                            disabled={disabled}
                            ref={index === activeOTPIndex ? inputRef : null}
                            type="number"
                            onKeyDown={(e) => handlerKeyDown(e, index)}
                            onChange={handlerOnChange}
                            value={otpNumber}
                            className={`w-14 h-14 md:w-[87px] md:h-[87px] rounded-2xl 2xl:rounded-3xl transition p-4 text-2xl leading-7 md:text-4xl text-txtcolor font-medium text-center focus:outline-0 focus:ring-1 focus:ring-primary ${
                                error ? "is-invalid" : "bg-inputbg border-transparent"
                            }`}
                        />
                    </React.Fragment>
                ))}
            </div>
            {error && <p className="text-red-500 mt-[10px]">{t("Please enter valid code")}</p>}
        </div>
    );
};

export default OTPField;
