import React from "react";
import Facebook from "@assets/img/facebook.svg";
import Instgram from "@assets/img/insta.svg";
import FeedBack from "@assets/svgs/feedback 1.svg";
import Img2 from "@assets/img/categoryImg/placeholder.png";
import RightArrow from "@assets/svgs/arroRight.svg";
import Browser from "@assets/img/world.svg";
import CommonModal from "../../../CommonModal";
import ButtonCmp from "../../../../components/ButtonCmp";
import { useTranslation } from "react-i18next";

interface IProps {
    setIsShowWhatsNewModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const WhatsNewDetailsModal = ({ setIsShowWhatsNewModal }: IProps) => {
    const { t } = useTranslation();
    return (
        <CommonModal handleClose={() => setIsShowWhatsNewModal(false)} size="max-w-[600px]" bodyClass="md:pt-6 md:pb-10">
            <div className="h-full">
                <div className="text-center">
                    <h1 className="block tracking-[-0.5px] text-xl font-bold mb-[30px] md:mb-6">{t("Whats New")}</h1>
                </div>
                <div className="w-full">
                    <img src={Img2} className="rounded-3xl w-full h-[160px]" alt="" />
                </div>
                <div className="mt-[30px] md:mt-5">
                    <h1 className="text-txtcolor text-base md:text-[28px] font-semibold leading-[124%] tracking-[-0.336px]">{t("FLAIR Studios is now open online!")}</h1>
                    <p className="tracking-[-0.14px] font-normal text-xs md:text-sm mt-[10px] md:mt-4">{t("Check out our service menu online and book your next appointment now.")}</p>
                    <hr className="my-[30px] md:my-8 text-inputbg" />
                    <ButtonCmp className="btn_primary w-full fl-btn font-bold">
                        <div className="flex gap-[5px] items-center">
                            {t("Open")} <img src={RightArrow} alt="" className="w-3.5" />
                        </div>
                    </ButtonCmp>
                </div>
                <div className="flex justify-center mt-[30px] md:mt-8">
                    <div className="flex gap-8">
                        <span className="p-[10px] rounded-lg cursor-pointer bg-inputbg">
                            <img src={Browser} alt="" />
                        </span>
                        <span className="p-[10px] rounded-lg cursor-pointer bg-inputbg">
                            <img src={Facebook} alt="" />
                        </span>
                        <span className="p-[10px] rounded-lg cursor-pointer bg-inputbg">
                            <img src={Instgram} alt="" />
                        </span>
                        <span className="p-[10px] rounded-lg cursor-pointer bg-inputbg">
                            <img src={FeedBack} alt="" />
                        </span>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
};

export default WhatsNewDetailsModal;
