import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import GuardedRoute from "./GuardedRoute";
import NotGuardedRoute from "./NotGuardedRoute";
import { Screens } from "./Screens";
import { ERouteType, RouteItem } from "../constants/common";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setCurrentBreakpoint, setIsMobile } from "../redux/reducers/Ui.slice";
import { updateMetaThemeColor } from "../utils/global-functions";
import AppLayout from "../app/AppLayout";

const MainRoutes = (): JSX.Element => {
    // const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);

    const updateMobileState = () => {
        const isMobileState = window.innerWidth <= 767;
        if (window.innerWidth > 1200 && window.innerWidth <= 1380 && uiState.currentBreakpoint !== "LG") {
            dispatch(setCurrentBreakpoint("LG"));
        }
        if (window.innerWidth > 1380 && window.innerWidth <= 1680 && uiState.currentBreakpoint !== "XL") {
            dispatch(setCurrentBreakpoint("XL"));
        }
        if (window.innerWidth > 1680 && uiState.currentBreakpoint !== "2XL") {
            dispatch(setCurrentBreakpoint("2XL"));
        }
        if (isMobileState === uiState.isMobile) {
            return;
        }
        dispatch(setIsMobile(isMobileState));
        // isMobile global state logic
        // if (!uiState.isMobilePageVisitedOnce) {
        //     navigate(ROUTES.MOBILE_LANDING);
        // }
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(updateMobileState);
        });

        resizeObserver.observe(document.documentElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, [uiState.isMobile, uiState.isMobilePageVisitedOnce, uiState.currentBreakpoint]);

    useEffect(() => {
        updateMetaThemeColor("white");
    }, []);

    const getRoute = (route: RouteItem): JSX.Element => {
        switch (route.type) {
            case ERouteType.GUARDED:
                return <Route key={route.path} path={route.path} element={<GuardedRoute Component={route.component} title={route.title} />} />;
            case ERouteType.NOT_GUARDED:
                return <Route key={route.path} path={route.path} element={<NotGuardedRoute Component={route.component} title={route.title} />} />;
            default:
                return <Route key={route.path} path={route.path} element={<NotGuardedRoute Component={route.component} title={route.title} />} />;
        }
    };

    return (
        <Suspense fallback={<AppLayout isLoading />}>
            <Routes>
                <Route element={<AppLayout />}>{Screens.map((item): JSX.Element => getRoute(item))}</Route>
                <Route path="*" element={<Navigate to="/not-found" />} />
            </Routes>
        </Suspense>
    );
};

export default MainRoutes;
