import React from "react";

interface ArrowBackIconProps {
    fillColor: string;
}
const ArrowBackIcon: React.FC<ArrowBackIconProps> = ({ fillColor }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.7915 11.0046H7.62148L12.5015 6.12461C12.8915 5.73461 12.8915 5.09461 12.5015 4.70461C12.1115 4.31461 11.4815 4.31461 11.0915 4.70461L4.50148 11.2946C4.11148 11.6846 4.11148 12.3146 4.50148 12.7046L11.0915 19.2946C11.4815 19.6846 12.1115 19.6846 12.5015 19.2946C12.8915 18.9046 12.8915 18.2746 12.5015 17.8846L7.62148 13.0046H18.7915C19.3415 13.0046 19.7915 12.5546 19.7915 12.0046C19.7915 11.4546 19.3415 11.0046 18.7915 11.0046Z"
            fill={fillColor}
        />
    </svg>
);

export default ArrowBackIcon;
