import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import { Spinner } from "flowbite-react";

interface IProps {
    isLoading?: boolean;
}

const AppLayout = ({ isLoading }: IProps) => {
    const location = useLocation();
    const [isShowFooter, setIsShowFooter] = useState(false);

    useEffect(() => {
        const isShowFtr = !["/profile-setting"].includes(location.pathname);
        setIsShowFooter(isShowFtr);
    }, [location.pathname]);

    return (
        <>
            <Header />
            <div className={`${isShowFooter ? "app_layout_with_footer" : "app_layout"} `}>
                {isLoading ? (
                    <div className="h-full w-full flex justify-center items-center">
                        <Spinner size="xl" />
                    </div>
                ) : (
                    <Outlet />
                )}
            </div>
            {isShowFooter && (
                <div className="hidden lg:block">
                    <Footer />
                </div>
            )}
        </>
    );
};

export default AppLayout;
