import React, { useEffect, useRef, useState } from "react";
import Logo from "@assets/svgs/Union.svg";
import Notification from "@assets/svgs/notification.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Login from "../Login";
import CompleteProfileModal from "../Login/CompleteProfileModal";
import NotificationCard from "../../components/HeaderComponents/NotificationCard";
import ProfileMenuComponent from "../../components/HeaderComponents/ProfileMenuComponent";
import { useNavigate } from "react-router-dom";
import Default_user_image from "@assets/img/default_user_image.png";
import { setIsShowLoginModal, setNotificationToken } from "../../redux/reducers/Login.slice";
import { errorCode } from "../../constants/common";
import { toast } from "react-toastify";
import { onMessageListener, requestForFirebaseToken } from "../../services/API/firebase";
import { axiosPost } from "../../utils/requestClient";
import { API } from "../../constants/api";
import GlobalShopSelection from "../../components/GlobalShopSelection";
import { useTranslation } from "react-i18next";
import CommonModal from "../../components/CommonModal";
import { EForgetPasswordStep } from "../Login/Login.interface";
import { Notifications } from "../../components/SvgColor/svgIcons";
import { axiosGet } from "../../utils/requestClient";

const Header = () => {
    const { t } = useTranslation();
    const notificationButtonRef = useRef(null);
    const profileButtonRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const [isShowCompleteProfileModal, setIsShowCompleteProfileModal] = useState(false);
    const [isShowProfileDropDown, setIsShowProfileDropDown] = useState(false);
    const [isShowNotificationModal, setIsShowNotificationModal] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [loginStep, setLoginStep] = useState<EForgetPasswordStep>(EForgetPasswordStep.FORGET_PASSWORD);
    const [shopDataList, setShopDataList] = useState<any>([]);
    const [companyLogoLoaded, setCompanyLogoLoaded] = useState(false);
    const [isCompanyName, setCompanyName] = useState<any>([]);
    const companyLogo = shopDataList?.logo_image_url;
    const showName = isCompanyName?.is_show_name;
    useEffect(() => {
        if (AuthUserState.user) {
            if (!(AuthUserState.user as any).is_completed_account && AuthUserState.isAuthenticated) {
                setIsShowCompleteProfileModal(true);
            }
        }
    }, [AuthUserState.user]);

    useEffect(() => {
        getOrUpdateFirebaseNotificationToken();

        if (AuthUserState.isAuthenticated) {
            onMessageListener()
                .then((payload: any) => {
                    if (!(payload?.notification.title === "Appointment Requested")) {
                        toast.success(payload?.notification?.body);
                    }
                })
                .catch((err) => {});
        }
    }, [AuthUserState.isAuthenticated]);
    useEffect(() => {
        loadShopData({});
    }, []);

    const loadShopData = async (payloadObj: any) => {
        try {
            const response = await axiosGet(API.USER_SHOP.USER_SHOP_GET, payloadObj, {
                shop_id: AuthUserState.globalShop?.value,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setShopDataList(response.data.data);
                setCompanyLogoLoaded(true);
                const settings = response.data.data.settings;
                if (settings && settings.length > 0) {
                    const webSetting = settings.find((setting: { type: string }) => setting.type === "web");
                    if (webSetting) {
                        setCompanyName(webSetting);
                    }
                }
                return;
            }
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        }
    };
    const getOrUpdateFirebaseNotificationToken = async () => {
        let FCM_TOKEN = await requestForFirebaseToken();
        if (!FCM_TOKEN) {
            FCM_TOKEN = await requestForFirebaseToken();
        }

        if (FCM_TOKEN && FCM_TOKEN !== AuthUserState.notificationToken && AuthUserState.isAuthenticated) {
            dispatch(setNotificationToken(FCM_TOKEN));

            try {
                const response = await axiosPost(API.NOTIFICATION.USER_NOTIFICATION_CREATE_UPDATE, {
                    token: FCM_TOKEN,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            }
        }
    };
    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpen(false);
    };
    return (
        <>
            <div className="h-[60px] items-center flex justify-between w-full px-5 md:px-10 md:border-b border-lightgray relative">
                <div className="flex items-center cursor-pointer" onClick={() => navigate("/")}>
                    {companyLogoLoaded && <img src={companyLogo ? companyLogo : Logo} className="max-w-48 max-h-[2.7rem] me-2" alt="" />}
                    <p>{showName ? shopDataList.business_name : ""}</p>
                </div>
                <div className="w-[140px] md:w-[280px]">
                    <GlobalShopSelection />
                </div>

                {AuthUserState.isAuthenticated && (
                    <div className="flex gap-2">
                        <span
                            ref={notificationButtonRef}
                            onClick={() => {
                                setIsShowNotificationModal(!isShowNotificationModal);
                                setIsShowProfileDropDown(false);
                            }}
                            className="px-2 py-2 cursor-pointer w-9 rounded-md border-[1px] custom-border"
                        >
                            {/* <img src={Notification} alt="" className="w-full" /> */}
                            <Notifications fillColor="var(--icon-color)" />
                        </span>
                        <div
                            ref={profileButtonRef}
                            onClick={() => {
                                setIsShowNotificationModal(false);
                                setIsShowProfileDropDown(!isShowProfileDropDown);
                            }}
                            className="flex cursor-pointer items-center border custom-border rounded-lg w-9 h-9"
                        >
                            <img src={AuthUserState.user?.profile_image_url ?? Default_user_image} className="w-full h-full rounded-[7px]" alt="" />
                        </div>
                    </div>
                )}
                {!AuthUserState.isAuthenticated ? (
                    <button className="tracking-[0em] btn_outline_primary" onClick={() => dispatch(setIsShowLoginModal(true))}>
                        {t("Login")}
                    </button>
                ) : (
                    ""
                )}
                {isShowProfileDropDown && (
                    <div className="absolute right-9 z-20 top-16 ">
                        <ProfileMenuComponent handleClose={() => setIsShowProfileDropDown(false)} profileButtonRef={profileButtonRef} />
                    </div>
                )}
            </div>

            {AuthUserState.isShowLoginModal && (
                <Login
                    onCloseModal={async () => {
                        if (loginStep === EForgetPasswordStep.SET_PASSWORD) {
                            setIsSuccessModalOpen(true);
                            await setLoginStep(EForgetPasswordStep.FORGET_PASSWORD);
                        }
                        dispatch(setIsShowLoginModal(false));
                    }}
                    loginStep={loginStep}
                    setLoginStep={setLoginStep}
                />
            )}

            {isShowCompleteProfileModal && <CompleteProfileModal handleCloseModal={() => setIsShowCompleteProfileModal(false)} />}

            {isShowNotificationModal && <NotificationCard handleClose={() => setIsShowNotificationModal(false)} notificationButtonRef={notificationButtonRef} />}
            {isSuccessModalOpen && (
                <CommonModal size="max-w-[600px]" handleClose={handleCloseSuccessModal} bodyClass="md:pt-10 md:pb-10">
                    <h1 className="text-xl md:text-[32px] -tracking-[0.24px] md:-tracking-[0.384px] leading-6 md:leading-10 font-semibold mb-4 text-txtcolor">{t("Password Changed!")}</h1>
                    <div className="mb-[30px] md:mb-10">
                        <p className=" text-txtcolor opacity-7 tracking-[-0.14px] text-[14px] font-normal">{t("Your password has been successfully changed!")}</p>
                    </div>
                </CommonModal>
            )}
        </>
    );
};

export default Header;
