import React, { useEffect } from "react";
import { RouteProps } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

type NotGuardedRouteProps = {
    Component: React.ComponentType<any>;
    title?: string;
} & RouteProps;

const NotGuardedRoute: React.FC<NotGuardedRouteProps> = ({ Component, title }) => {
    const authUserState = useAppSelector((data) => data.AuthUser);

    useEffect(() => {
        document.title = `${title} - ${authUserState.globalShop?.label ?? "FLAIR"}`;
    }, [title, authUserState.globalShop]);

    // always allowing visit to all the pages
    return <Component />;
};

export default NotGuardedRoute;
