import React, { useEffect, useState } from "react";
import Exit from "@assets/svgs/exit.svg";
import Drawer from "../../../Drawer";
import WhatsNewCardComponent from "../WhatsNewCardComponent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import BarLoader from "../../../BarLoader";
import { useTranslation } from "react-i18next";

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
}

const ViewWhatsNewAllDrawer = ({ isOpen, handleClose }: IProps) => {
    const { t } = useTranslation();
    const testData = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }];

    const [allNotifications, setAllNotifications] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
        filters: null,
        first: 0,
        multiSortMeta: [],
        page: 0,
        pageCount: 5,
        rows: 10,
        sortField: null,
        sortOrder: null,
    });

    let networkTimeout: any = null;

    useEffect(() => {
        if (isOpen) {
            loadLazyData();
        }
    }, [lazyState, isOpen]);

    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }

        //imitate delay of a backend call
        networkTimeout = setTimeout(() => {
            setTotalRecords(50);
            setAllNotifications([...allNotifications, ...testData]);
            setLoading(false);
        }, Math.random() * 1000 + 250);
    };

    const onPage = (event: any) => {
        setlazyState(event);
    };

    return (
        <Drawer isOpen={isOpen} drawerClass="max-w-xl">
            <div className="flex flex-col justify-between h-full">
                <div className="p-8 pb-4">
                    <div className="flex justify-between">
                        <h1 className=" h-full font-bold text-xl text-txtcolor tracking-[-0.5px]">{t("Whats New")}</h1>
                        <img onClick={handleClose} src={Exit} alt="" className="cursor-pointer" />
                    </div>
                    <div className="w-1/2 mt-2 mb-2">{loading && <BarLoader />}</div>
                </div>
                <div className="h-[calc(100%-76px)] overflow-x-auto scrollbar-hide px-8">
                    <DataTable
                        scrollable
                        scrollHeight="flex"
                        size="small"
                        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
                        currentPageReportTemplate="{first}-{last} of {totalRecords}"
                        className="datatable-no-thead"
                        paginatorClassName="datatable-no-thead-paginator text-sm"
                        value={allNotifications}
                        lazy
                        filterDisplay="row"
                        dataKey="id"
                        paginator
                        first={lazyState.first}
                        rows={10}
                        totalRecords={totalRecords}
                        onPage={onPage}
                        loading={loading}
                        loadingIcon={<></>}
                    >
                        <Column body={(rowData) => <WhatsNewCardComponent key={rowData.id} className="mb-1" />}></Column>
                    </DataTable>
                </div>
            </div>
        </Drawer>
    );
};

export default ViewWhatsNewAllDrawer;
