import React from "react";

interface ExitIconProps {
    fillColor: string;
}
const ExitIcon: React.FC<ExitIconProps> = ({ fillColor }) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.909305 13.7501C0.778913 13.7502 0.651443 13.7115 0.543019 13.6391C0.434594 13.5666 0.350086 13.4637 0.300184 13.3432C0.250282 13.2228 0.237228 13.0902 0.262673 12.9623C0.288118 12.8344 0.350919 12.717 0.443132 12.6248L12.6248 0.443095C12.7484 0.319458 12.9161 0.25 13.091 0.25C13.2658 0.25 13.4335 0.319458 13.5572 0.443095C13.6808 0.566732 13.7503 0.73442 13.7503 0.909269C13.7503 1.08412 13.6808 1.25181 13.5572 1.37544L1.37548 13.5571C1.31432 13.6184 1.24165 13.667 1.16165 13.7001C1.08165 13.7333 0.995891 13.7502 0.909305 13.7501Z"
            fill={fillColor}
        />
        <path
            d="M13.0909 13.7501C13.0044 13.7502 12.9186 13.7333 12.8386 13.7001C12.7586 13.667 12.6859 13.6184 12.6248 13.5571L0.443095 1.37544C0.319458 1.25181 0.25 1.08412 0.25 0.909269C0.25 0.73442 0.319458 0.566732 0.443095 0.443095C0.566732 0.319458 0.73442 0.25 0.909269 0.25C1.08412 0.25 1.25181 0.319458 1.37544 0.443095L13.5571 12.6248C13.6493 12.717 13.7121 12.8344 13.7376 12.9623C13.763 13.0902 13.75 13.2228 13.7001 13.3432C13.6502 13.4637 13.5657 13.5666 13.4572 13.6391C13.3488 13.7115 13.2213 13.7502 13.0909 13.7501Z"
            fill={fillColor}
        />
    </svg>
);

export default ExitIcon;
