import React, { ComponentType, LazyExoticComponent } from "react";
import { ERouteType, RouteItem } from "../constants/common";
import { ROUTES } from "../constants/routes";

const getLazyPage = (path: string): LazyExoticComponent<ComponentType> => React.lazy((): Promise<{ default: ComponentType }> => import(`../app/${path}`));

export const Screens: RouteItem[] = [
    {
        type: ERouteType.NOT_GUARDED,
        path: ROUTES.HOME,
        component: getLazyPage("Home"),
        title: "Home",
    },
    {
        type: ERouteType.NOT_GUARDED,
        path: ROUTES.MOBILE_LANDING,
        component: getLazyPage("MobileLanding"),
        title: "Mobile",
    },
    {
        type: ERouteType.NOT_GUARDED,
        path: ROUTES.BOOK_APPOINTMENT,
        component: getLazyPage("BookAppointment"),
        title: "New Appointment",
    },
    {
        type: ERouteType.GUARDED,
        path: ROUTES.PROFILE_SETTING,
        component: getLazyPage("ProfileSetting"),
        title: "Profile",
    },
    {
        type: ERouteType.NOT_GUARDED,
        path: ROUTES.PAGE_NOT_FOUND,
        component: getLazyPage("PageNotFound"),
        title: "Page Not Found",
    },
];
