import React, { ReactElement } from "react";

export interface IPops {
    disabled?: boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, index?: number) => void;
    value: string;
    name: string;
    placeholder: string;
    label?: string;
    type?: string;
    index?: number;
    handlerIconClick?: () => void;
    buttonIcon?: ReactElement | null;
    inputClass?: string;
    min?: number;
}

const InputWithLabel: React.FC<IPops> = ({ disabled = false, handleChange, value, name, placeholder, label, type, index, handlerIconClick, buttonIcon, inputClass, min }) => (
    <div className="form_group w-full">
        {label && (
            <label htmlFor="" className="fl-field-title-label">
                {label}
            </label>
        )}
        <div className="relative w-full">
            <input
                className={`${inputClass} ba_form_control text-txtcolor`}
                type={type || "text"}
                name={name}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, index)}
                placeholder={placeholder}
                disabled={disabled}
                min={min}
            />
            {buttonIcon && (
                <span onClick={handlerIconClick ?? undefined} className="cursor-pointer absolute top-1/2 -translate-y-1/2 right-4">
                    {buttonIcon}
                </span>
            )}
        </div>
    </div>
);

export default InputWithLabel;
