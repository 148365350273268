import React from "react";
import CommonModal from "../CommonModal";
import ButtonCmp from "../ButtonCmp";

interface IProps {
    handleClose: () => void;
    successHandler: () => void;
    isSuccessLoading?: boolean;
    title: string;
    description: string;
    buttonSuccessText: string;
    buttonCancelText: string;
}

const ConfirmationModal = ({ handleClose, successHandler, isSuccessLoading, title, description, buttonSuccessText, buttonCancelText }: IProps) => (
    <CommonModal handleClose={() => (isSuccessLoading ? false : handleClose())} size="max-w-[407px]" bodyClass="md:pt-8 md:pb-10">
        <h1 className="mb-4 text-xl font-bold text-txtcolor text-center">{title}</h1>
        <p className="text-secondaryTxt text-sm font-normal text-center -tracking-[0.14px]">{description}</p>
        <div className="flex mt-8 flex-col w-full">
            <ButtonCmp className="btn_primary fl-btn w-full" onClick={handleClose} disabled={isSuccessLoading}>
                {buttonCancelText}
            </ButtonCmp>
            <ButtonCmp className="btn_secondary_black mt-3 fl-btn w-full" onClick={successHandler} disabled={isSuccessLoading} loading={isSuccessLoading}>
                {buttonSuccessText}
            </ButtonCmp>
        </div>
    </CommonModal>
);

export default ConfirmationModal;
