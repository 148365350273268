import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="flex justify-end px-6 md:px-10 h-[40px] items-center w-full bg-contentBackground bg-opacity-75 backdrop-blur">
            <div className="item font-medium -tracking-[0.14px]">
                <span className="text-secondaryTxt">{t("© 2023")} </span> <span className="text-txtcolor">{t("getflair.ca")}</span>
            </div>
        </div>
    );
};
export default Footer;
