import React, { memo, useEffect } from "react";
import { HiXMark } from "react-icons/hi2";

interface IProps {
    handleClose: () => void;
    children: React.ReactNode;
    size?: string | "max-w-md";
    isClosable?: boolean;
    bodyClass?: string;
}

const CommonModal = ({ size, handleClose, children, bodyClass, isClosable = true }: IProps) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        document.addEventListener("keydown", navigationPreventionHandler, false);
        return () => {
            document.body.style.overflow = "visible";
            document.removeEventListener("keydown", navigationPreventionHandler, false);
        };
    }, []);

    const navigationPreventionHandler = (e: any) => {
        if ((e.metaKey || e.ctrlKey) && Number(e.keyCode ?? "0") === 37) {
            // left arrow key.
            e.preventDefault();
        }
    };

    return (
        <div className="modal_backdrop">
            <div id="small-modal" className="modal_content">
                <div className={`relative w-full max-h-full ${size}`}>
                    <div className={`mb-4 lg:mb-0 bg-contentBackground  rounded-3xl shadow dark:bg-gray-700 px-5 py-5 md:px-10 md:pt-5 ${bodyClass}`}>
                        {isClosable && (
                            <button onClick={() => (isClosable ? handleClose() : false)} className="absolute top-0 right-0 p-5">
                                <HiXMark size={24} className="text-txtcolor" />
                            </button>
                        )}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default memo(CommonModal);
