export enum EAuthFlowType {
    PHONE = "login-with-phone",
    EMAIL = "login-with-email",
    FORGET_PASSWORD = "forget-password",
}

export enum ELoginWithPhoneStep {
    LOGIN = "login",
    OTP = "otp",
}

export enum EForgetPasswordStep {
    FORGET_PASSWORD = "forget-password",
    OTP = "otp",
    SET_PASSWORD = "set-password",
}
