import React, { useEffect, useState } from "react";
import OTPField from "./OTPField";
import ButtonCmp from "../ButtonCmp";
import { CountryCode } from "libphonenumber-js";
import { toast } from "react-toastify";
import { errorCode } from "../../constants/common";
import { axiosPatch, axiosPost } from "../../utils/requestClient";
import { API } from "../../constants/api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userLoginForgetPasswordVerifyOtp, userMe, userPhoneLoginVerifyOtp } from "../../redux/reducers/Login.slice";
import { LocalStorageKeys } from "../../constants/keys";
import { useTranslation } from "react-i18next";

interface IProps {
    // will be using this while using this with forget password flow
    handleNextStep: () => void;
    phone?: string;
    email?: string;
    countryCode?: CountryCode;
    isUpdating?: boolean;
    formatedPhoneNumber?: string;
    countryDigit?: string;
}

const Otp = ({ handleNextStep, phone, email, countryCode, isUpdating, formatedPhoneNumber, countryDigit }: IProps) => {
    const { t } = useTranslation();
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const dispatch = useAppDispatch();

    const mainNumber: string | undefined = countryDigit && formatedPhoneNumber && `(+${countryDigit}) ${formatedPhoneNumber}`;

    const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
    const [counter, setCounter] = React.useState(60);
    const [isSendOtpAgainLoading, setIsSendOtpAgainLoading] = useState(false);
    const [otpVerificationFailedError, setOtpVerificationFailedError] = useState<string | undefined>(undefined);
    const [isOtpVerifyWithUpdateLoading, setIsOtpVerifyWithUpdateLoading] = useState(false);

    useEffect(() => {
        setOtpVerificationFailedError(undefined);
    }, [otp]);

    useEffect(() => {
        const timer: NodeJS.Timeout | false = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [counter]);

    const handleSubmit = async () => {
        if (email && isUpdating) {
            try {
                setIsOtpVerifyWithUpdateLoading(true);
                const response = await axiosPost(
                    API.USER.USER_PROFILE_EMAIL_UPDATE_WITH_OTP_VERIFY,
                    {
                        email: email,
                        otp: otp.join(""),
                    },
                    {},
                );
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    await dispatch(userMe({}));
                    handleNextStep();
                    return;
                }

                if (response.data?.status === errorCode.unprocessable) {
                    Object.keys(response.data.data).forEach((field) => {
                        setOtpVerificationFailedError(response.data.data[field][0]);
                    });
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message ?? "Something went wrong, Please try again");
            } finally {
                setIsOtpVerifyWithUpdateLoading(false);
            }
        } else if (phone && countryCode && isUpdating) {
            try {
                setIsOtpVerifyWithUpdateLoading(true);
                const response = await axiosPost(
                    API.USER.USER_PROFILE_PHONE_UPDATE_WITH_OTP_VERIFY,
                    {
                        phone: phone,
                        otp: otp.join(""),
                    },
                    {},
                );
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    await dispatch(userMe({}));
                    handleNextStep();
                    return;
                }

                if (response.data?.status === errorCode.unprocessable) {
                    Object.keys(response.data.data).forEach((field) => {
                        setOtpVerificationFailedError(response.data.data[field][0]);
                    });
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message ?? "Something went wrong, Please try again");
            } finally {
                setIsOtpVerifyWithUpdateLoading(false);
            }
        } else if (email) {
            // Forget email password with verify otp flow
            const payloadData = {
                email: email,
                otp: otp.join(""),
            };
            const result = await dispatch(userLoginForgetPasswordVerifyOtp(payloadData));

            if (result.type === userLoginForgetPasswordVerifyOtp.fulfilled.toString()) {
                localStorage.setItem(LocalStorageKeys.authToken, result.payload.data.token);
                handleNextStep();
            }

            if (result.type === userLoginForgetPasswordVerifyOtp.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    setOtpVerificationFailedError(response.message);
                    return;
                }
                toast.error(response.message);
            }
        } else if (phone && countryCode) {
            // Login with phone and verify otp flow
            const payloadData = {
                phone: phone,
                otp: otp.join(""),
            };
            const result = await dispatch(userPhoneLoginVerifyOtp(payloadData));

            if (result.type === userPhoneLoginVerifyOtp.fulfilled.toString()) {
                localStorage.setItem(LocalStorageKeys.authToken, result.payload.data.token);
                handleNextStep();
            }

            if (result.type === userPhoneLoginVerifyOtp.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    setOtpVerificationFailedError(response.message);
                    return;
                }
                toast.error(response.message);
            }
        }
    };

    const handleSendOtpAgain = async () => {
        setOtp(new Array(4).fill(""));
        if (counter === 0 && email && isUpdating) {
            try {
                setIsSendOtpAgainLoading(true);
                const response = await axiosPatch(API.USER.USER_PROFILE_EMAIL_UPDATE, { email: email });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    setCounter(60);
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsSendOtpAgainLoading(false);
            }
        } else if (counter === 0 && phone && countryCode && isUpdating) {
            try {
                setIsSendOtpAgainLoading(true);
                const response = await axiosPatch(API.USER.USER_PROFILE_PHONE_UPDATE, {
                    phone: phone,
                    phone_country_code: countryCode,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    setCounter(60);
                    return;
                }
                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsSendOtpAgainLoading(false);
            }
        } else if (counter === 0 && email) {
            try {
                setIsSendOtpAgainLoading(true);
                const response = await axiosPost(API.USER.FORGET_PASSWORD_SEND_OTP, {
                    email: email,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    setCounter(60);
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsSendOtpAgainLoading(false);
            }
        } else if (counter === 0 && phone && countryCode) {
            setIsSendOtpAgainLoading(true);
            try {
                const response = await axiosPost(API.USER.LOGIN_WITH_PHONE, {
                    phone: phone,
                    phone_country_code: countryCode,
                });

                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    setCounter(60);
                    return;
                }
                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsSendOtpAgainLoading(false);
            }
        }
    };
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <div className="" onKeyPress={handleKeyPress}>
            <div className="text-start">
                <div className="flex justify-between items-center mb-4 xl:mb-0 lg:mb-0 md:mb-0">
                    <h5 className="font-semibold tracking-[-0.01em] text-txtcolor text-[16px] md:text-[32px]">Enter the 4-digit code</h5>
                </div>
                <div className="text-gray-600 mt-3 text-sm font-normal tracking-[-0.01em] text-start mb-6">
                    {t("The 4-digit code has been sent to")}
                    <span className="text-start text-txtcolor font-bold md:pt-2 ml-1">{mainNumber ?? email}</span>
                </div>
            </div>
            <div className="mx-auto my-0">
                <OTPField otp={otp} setOtp={setOtp} disabled={isOtpVerifyWithUpdateLoading} error={otpVerificationFailedError} />
            </div>
            <div className="mt-[30px] md:mt-8 flex flex-col items-center">
                <p className="text-secondaryTxt  text-sm tracking-[-0.01em]">{t("Haven't received the code yet?")}</p>
                <span className="text-sm mt-2 font-[700] text-txtcolor">
                    {counter === 0 ? (
                        <button disabled={isSendOtpAgainLoading || isOtpVerifyWithUpdateLoading} onClick={handleSendOtpAgain} className="tracking-[-0.01em] text-sm">
                            <span>{t("Send again")} </span>
                        </button>
                    ) : (
                        <>
                            <span className={`tracking-[-0.01em] text-sm`}>{t("Expires in")}</span>
                            <span className="text-sm font-[700]  text-primary">
                                <span>
                                    {" "}
                                    {counter} {t("seconds")}
                                </span>
                            </span>
                        </>
                    )}
                </span>
            </div>
            <ButtonCmp
                onClick={handleSubmit}
                className="btn_primary fl-btn w-full mt-[30px] md:mt-10"
                disabled={otp.join("").length !== 4 || isSendOtpAgainLoading || isOtpVerifyWithUpdateLoading}
                loading={isOtpVerifyWithUpdateLoading}
            >
                {t("Continue")}
            </ButtonCmp>
        </div>
    );
};

export default Otp;
