import { createSlice } from "@reduxjs/toolkit";
import { IShopLocation, ISpecialist, ISpecialistService } from "../../Interface";

const initialState = {
    modifyingAppointmentId: undefined,
    isModifyingAppointment: false,
    isShowFinalizeBooking: false,
    selectedShopLocation: undefined,
    selectedSpecialist: undefined,
    selectedBookingDate: undefined,
    selectedBookingTime: undefined,
    selectedServices: [],
};

interface IStateType {
    modifyingAppointmentId: number | undefined;
    isModifyingAppointment: boolean;
    isShowFinalizeBooking: boolean;
    selectedShopLocation: IShopLocation | undefined;
    selectedSpecialist: ISpecialist | undefined;
    selectedBookingDate: string | undefined;
    selectedBookingTime: string | undefined;
    selectedServices: ISpecialistService[];
}

export const bookingSlice = createSlice({
    name: "booking",
    initialState: initialState as IStateType,
    reducers: {
        setModifyingAppointmentId: (state, { payload }) => {
            state.modifyingAppointmentId = payload;
        },
        setIsModifyingAppointment: (state, { payload }) => {
            state.isModifyingAppointment = payload;
        },
        setIsShowFinalizeBooking: (state, { payload }) => {
            state.isShowFinalizeBooking = payload;
        },
        setSelectedShopLocation: (state, { payload }) => {
            state.selectedShopLocation = payload;
        },
        setSelectedSpecialist: (state, { payload }) => {
            state.selectedSpecialist = payload;
        },
        setSelectedBookingDate: (state, { payload }) => {
            state.selectedBookingDate = payload;
        },
        setSelectedBookingTime: (state, { payload }) => {
            state.selectedBookingTime = payload;
        },
        setSelectedServices: (state, { payload }) => {
            state.selectedServices = payload;
        },
    },
});

export const {
    setModifyingAppointmentId,
    setIsModifyingAppointment,
    setIsShowFinalizeBooking,
    setSelectedShopLocation,
    setSelectedSpecialist,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setSelectedServices,
} = bookingSlice.actions;

export default bookingSlice.reducer;
