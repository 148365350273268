import React, { useEffect, useState } from "react";
import SelectBox from "../SelectBox";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toast } from "react-toastify";
import { errorCode } from "../../constants/common";
import axios from "axios";
import { setGlobalShop, setShopData } from "../../redux/reducers/Login.slice";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { axiosGet } from "../../utils/requestClient";
import { API } from "../../constants/api";

const globalShopId = process.env.REACT_APP_FLAIR_SHOP_ID;

const GlobalShopSelection = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const authUserState = useAppSelector((data) => data.AuthUser);
    const [shopsList, setShopsList] = useState([]);
    const [shopDataList, setShopDataList] = useState([]);

    useEffect(() => {
        loadShops();
    }, []);

    useEffect(() => {
        loadShopDataTheme({});
    }, []);

    const loadShops = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/shop/list?type=json`);

            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                if (globalShopId) {
                    const globalShopData = response.data.data.find((shop: any) => shop.id === Number(globalShopId));
                    if (globalShopData) {
                        dispatch(setShopData(globalShopData));
                        dispatch(
                            setGlobalShop({
                                value: globalShopData.id,
                                label: globalShopData.business_name,
                            }),
                        );
                    }
                } else {
                    setShopDataList(response.data.data);
                    setShopsList(
                        response.data.data.map((shop: any, index: number) => {
                            if (index === 0 && authUserState.globalShop === null) {
                                dispatch(setShopData(shop));
                                dispatch(
                                    setGlobalShop({
                                        value: shop.id,
                                        label: shop.business_name,
                                    }),
                                );
                            } else if (shop.id === authUserState.globalShop?.value) {
                                dispatch(setShopData(shop));
                            }
                            return {
                                value: shop.id,
                                label: shop.business_name,
                            };
                        }),
                    );
                }
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please refresh the page.");
        }
    };

    const handleShopSelect = (option: any) => {
        if (option === null) {
            dispatch(setGlobalShop(option));
            loadShopDataTheme({});
        } else {
            const globalShopData = shopDataList.find((shop: any) => shop.id === option.value);

            if (globalShopData) {
                dispatch(setShopData(globalShopData));
                dispatch(setGlobalShop(option));
                loadShopDataTheme({});
            }
        }
    };
    const loadShopDataTheme = async (payloadObj: any) => {
        try {
            // setIsShopsDataLoading(true);
            const response = await axiosGet(API.USER_SHOP.USER_SHOP_GET, payloadObj, {
                shop_id: authUserState.globalShop?.value,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                const settings = response.data.data.settings;
                if (settings && settings.length > 0) {
                    const webSetting = settings.find((setting: { type: string }) => setting.type === "web");
                    if (webSetting) {
                        updateSecondaryTxtColor(webSetting);
                    }
                }
            }
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        } finally {
            // setIsShopsDataLoading(false);
        }
    };
    const updateSecondaryTxtColor = (webSetting: any) => {
        document.documentElement.style.setProperty("--secondary-txt-color", webSetting.secondary_text_color);
        document.documentElement.style.setProperty("--content-background-color", webSetting.content_background);
        document.documentElement.style.setProperty("--cards-color", webSetting.cards_color);
        document.documentElement.style.setProperty("--button-color", webSetting.button_color);
        document.documentElement.style.setProperty("--main-text-color", webSetting.main_text_color);
        document.documentElement.style.setProperty("--accent-color", webSetting.accent_color);
    };
    return globalShopId ? (
        <></>
    ) : (
        <SelectBox
            inputClass="w-full"
            placeholder={t("Select a shop")}
            name="category"
            allowClear={false}
            options={shopsList}
            onChange={handleShopSelect}
            value={authUserState.globalShop}
            disabled={location.pathname === "/appointment"}
        />
    );
};

export default GlobalShopSelection;
