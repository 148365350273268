import React, { useEffect, useState } from "react";
import Drawer from "../../../Drawer";
import Exit from "@assets/svgs/exit.svg";
import NotificationCardComponent from "../NotificationCardComponent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toast } from "react-toastify";
import { errorCode } from "../../../../constants/common";
import { axiosGet } from "../../../../utils/requestClient";
import { API } from "../../../../constants/api";
import { INotification } from "../../../../Interface/notification.interface";
import BarLoader from "../../../BarLoader";
import { useTranslation } from "react-i18next";
import ExitIcon from "../../../SvgColor/exitIcon";

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
}

const ViewAllNotificationDrawer = ({ isOpen, handleClose }: IProps) => {
    const { t } = useTranslation();
    const [allNotifications, setAllNotifications] = useState<INotification[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 6,
        page: 1,
    });

    useEffect(() => {
        if (isOpen) {
            loadLazyData();
        }
    }, [lazyState, isOpen]);

    const loadLazyData = async () => {
        try {
            setLoading(true);
            const response = await axiosGet(API.NOTIFICATION.USER_NOTIFICATION_CREATE_UPDATE, lazyState);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setTotalRecords(response.data.data.totalRecords);
                setAllNotifications(response.data.data.data);
                setLoading(false);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setLoading(false);
        }
    };

    const onPage = (event: any) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    return (
        <Drawer isOpen={isOpen} drawerClass="max-w-xl">
            <div className="h-full">
                <div className="p-8 pb-4">
                    <div className="flex justify-between">
                        <h1 className="h-full font-bold text-xl text-txtcolor tracking-[-0.5px]">{t("All notifications")}</h1>
                        <span onClick={handleClose}>
                            <ExitIcon fillColor="var(--icon-color)" />
                        </span>
                    </div>
                    <div className="w-1/2 mt-2 mb-2">{loading && <BarLoader />}</div>
                </div>

                <div className="h-[calc(100%-76px)] overflow-x-auto scrollbar-hide px-6 bg-cardColor">
                    <DataTable
                        scrollable
                        scrollHeight="flex"
                        size="small"
                        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
                        currentPageReportTemplate="{first}-{last} of {totalRecords}"
                        className="datatable-no-thead"
                        paginatorClassName="datatable-no-thead-paginator text-sm"
                        value={allNotifications}
                        lazy
                        dataKey="notification_all_id"
                        paginator
                        first={lazyState.first}
                        rows={lazyState.rows}
                        totalRecords={totalRecords}
                        onPage={onPage}
                        loading={loading}
                        loadingIcon={<></>}
                    >
                        <Column
                            body={(rowData: INotification) => (
                                <NotificationCardComponent
                                    key={`${rowData.name}_${rowData.id}`}
                                    className="flex gap-4 sm:py-4 py-2 items-center border-b border-line px-[8px] bg-cardColor"
                                    isNegative={false}
                                    notificationRecord={rowData}
                                />
                            )}
                        />
                    </DataTable>
                </div>
            </div>
        </Drawer>
    );
};

export default ViewAllNotificationDrawer;
