import React, { ReactNode } from "react";

interface IDrawerProps {
    children: ReactNode;
    isOpen: boolean;
    drawerClass?: string;
}

const Drawer = ({ children, isOpen, drawerClass }: IDrawerProps) => (
    <main
        className={`fixed overflow-hidden z-40 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out
                ${isOpen ? " transition-opacity opacity-100 duration-500 w-full" : " delay-500 opacity-0 translate-x-full"}
            `}
    >
        <section
            className={` ${drawerClass} drawer right-0 absolute bg-cardColor w-full h-screen shadow-xl delay-400 duration-500 ease-in-out transition-all transform ${
                isOpen ? " translate-x-0 " : " translate-x-full "
            }`}
        >
            {children}
        </section>
    </main>
);

export default Drawer;
