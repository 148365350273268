export interface IListOption {
    value: string;
    label: string;
}

export interface IUser {
    id: number;
    birthdate: string;
    created_at: string;
    device_token: string;
    email: string;
    email_verified_at: string;
    first_name: string;
    gender: string;
    is_allowed_notification: boolean;
    is_user_verified: boolean;
    last_name: string;
    location_id: string | number;
    login_type: string;
    phone: string;
    phone_country_code: string;
    profile_image_name: string;
    referral_code: string;
    referral_offer: string;
    referrer_id: string | number;
    remember_me: number | boolean;
    stripe_customer_id: string;
    updated_at: string;
    profile_image_url: string;
    notification: {
        referral: boolean;
        whats_new: boolean;
        promotions: boolean;
        booking_confirm: boolean;
        booking_canceled: boolean;
        booking_reschedule: boolean;
    };
}

export interface ILocation {
    lat: number;
    lng: number;
}

export interface IShopLocation {
    city: string;
    country: string;
    created_at: string;
    deleted_at: string | null;
    id: number;
    latitude: string;
    location_image_name: string;
    location_image_url: string;
    longitude: string;
    name: string;
    postal_code: string;
    sales_tax_id: number | string | null;
    shop_id: number;
    street: string;
    timezone: string;
    tips: string[];
    tips_type: "percentage" | "value";
    created_by: number;
    business_name: string;
    address: string;
    state: string;
    staff_size: string;
    logo_image_name: string;
    app_logo_image_name: string;
    contract_pdf_name: string;
    contract_name: string;
    contract_business_name: string;
    contract_business_address: string;
    website: string;
    google_review: string;
    facebook_page: string;
    instagram_page: string;
    is_book_with_card: boolean;
    updated_at: string;
    logo_image_url: string;
    app_logo_image_url: string;
    contract_pdf_url: string;
    sales_tax: IShopLocationSalesTax;
}

interface IShopLocationSalesTax {
    id: number;
    territory: string;
    gst: string;
    pst: string;
    hst: string;
    deleted_at: string;
    created_at: string;
    updated_at: string;
}

export interface ISpecialistRole {
    id: number;
    shop_id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

export interface ISpecialistService {
    id: number;
    shop_id: number;
    shop_category_id: number;
    name: string;
    tags: string[];
    description: string;
    is_active: boolean;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    price?: ISpecialistServicePrice;
    quantity?: number; // For storing booking quantity client side
}

export interface ISpecialistServicePrice {
    id: number;
    shop_id: number;
    staff_id: number;
    shop_service_id: number;
    starting_price: boolean;
    duration: string;
    price?: string;
    description: string;
    created_at: string;
    updated_at: string;
}

export interface ISpecialistImageGallary {
    id: number;
    shop_id: number;
    staff_id: number;
    name: string;
    created_at: string;
    updated_at: string;
    image_url: string;
}

export interface ISpecialist {
    id: number;
    stripe_customer_id: string;
    stripe_account_id: string;
    first_name: string;
    last_name: string;
    phone_country_code: string;
    phone: string;
    email: string;
    birth_date: string;
    about: string;
    profile_image: string;
    profile_image_url: string;
    instagram_link: string;
    is_notification: boolean;
    is_verified_stripe_account: boolean;
    notification: string | null;
    email_verified_at: string;
    phone_verified_at: string;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    role: ISpecialistRole;
    services: ISpecialistService[];
    galleries: ISpecialistImageGallary[];
    working_hours: IWorkingHour[];
    shop_staff: IShopStaffAccountProgress[];
}

interface IShopStaffAccountProgress {
    id: number;
    shop_id: number;
    staff_id: number;
    shop_staff_role_id: number;
    agreement_name: string;
    agreement_business_name: string;
    agreement_business_address: string;
    agreement_pdf_name: string;
    account_type: string;
    no_show: number;
    cancellation_policy: number;
    cancellation_window: string;
    appointment_requests: number;
    deleted_at: string;
    created_at: string;
    updated_at: string;
    vendor_agreement_url: string;
}

export interface IWorkingHour {
    id: number;
    shop_id: number;
    staff_id: number;
    shop_location_id: number;
    day: string;
    from: string;
    to: string;
    frequency_booking: string;
    status: boolean;
    created_at: string;
    updated_at: string;
}

export interface IShopLocationCategory {
    id: number;
    shop_id: number;
    name: string;
    description: string;
    is_active: boolean;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
}

export interface ISpecialistRoleCategory {
    id: number;
    shop_id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

export enum EStripeCardType {
    VISA = "visa",
    MASTER = "mastercard",
    AMERICAN = "amex",
    DINER = "diners",
    DISCOVER = "discover",
    JCB = "jcb",
    UNION = "unionpay",
}

export interface ISelectBoxOption {
    label: string;
    value: string | number;
}
