import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosGet, axiosPost } from "../../utils/requestClient";
import { API } from "../../constants/api";
import { errorCode } from "../../constants/common";
import { PURGE } from "redux-persist";
import { IUser } from "../../Interface";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const initialState = {
    globalShop: null,
    shopData: null,
    isShowLoginModal: false,
    user: undefined,
    token: undefined,
    loading: false,
    isAuthenticated: false,
    cookieSettingAnswer: undefined,
    notificationToken: undefined,
};

interface IStateType {
    globalShop: null | any;
    shopData: null | any;
    isShowLoginModal: boolean;
    user: IUser | undefined;
    token: string | undefined;
    loading: boolean;
    isAuthenticated: boolean;
    cookieSettingAnswer: string | undefined;
    notificationToken: string | undefined;
}

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}
export const userMe = createAsyncThunk("user/me", async (payload: any, { rejectWithValue }) => {
    const authUserState = useAppSelector((data) => data.AuthUser);

    try {
        const response = await await axiosGet(API.USER.USER_ME, {}, { shop_id: authUserState.globalShop?.value });
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const userPhoneLoginVerifyOtp = createAsyncThunk("user/login/verify-otp", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.LOGIN_WITH_PHONE_OTP_VERIFY, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const userLoginWithEmail = createAsyncThunk("user/login", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.LOGIN_WITH_EMAIL, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const userLoginForgetPasswordVerifyOtp = createAsyncThunk("user/forgot-password/verify-otp", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.FORGET_PASSWORD_VERIFY_OTP, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const userLogout = createAsyncThunk("user/logout", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.USER_LOGOUT, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const loginSlice = createSlice({
    name: "login",
    initialState: initialState as IStateType,
    reducers: {
        setGlobalShop: (state, { payload }) => {
            state.globalShop = payload;
        },
        setShopData: (state, { payload }) => {
            state.shopData = payload;
        },
        setNotificationToken: (state, { payload }) => {
            state.notificationToken = payload;
        },
        setIsShowLoginModal: (state, { payload }) => {
            state.isShowLoginModal = payload;
        },
        setCookieSettingAnswer: (state, { payload }) => {
            state.cookieSettingAnswer = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(PURGE, () => initialState)
            .addCase(userMe.pending, (state) => {
                state.loading = true;
            })
            .addCase(userMe.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = payload.data;
            })
            .addCase(userMe.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(userPhoneLoginVerifyOtp.pending, (state) => {
                state.loading = true;
            })
            .addCase(userPhoneLoginVerifyOtp.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = payload.data.user;
                state.token = payload.data.token;
                state.isAuthenticated = true;
            })
            .addCase(userPhoneLoginVerifyOtp.rejected, (state, action: any) => {
                state.loading = false;
                state.isAuthenticated = false;
            })
            .addCase(userLoginWithEmail.pending, (state) => {
                state.loading = true;
            })
            .addCase(userLoginWithEmail.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = payload.data.user;
                state.token = payload.data.token;
                state.isAuthenticated = true;
            })
            .addCase(userLoginWithEmail.rejected, (state, action: any) => {
                state.loading = false;
                state.isAuthenticated = false;
            })
            // .addCase(userLoginForgetPasswordVerifyOtp.pending, (state) => {
            //     state.loading = true;
            // })
            // .addCase(userLoginForgetPasswordVerifyOtp.fulfilled, (state, { payload }: any) => {
            //     state.loading = false;
            //     // state.user = payload.data.user;
            //     // state.token = payload.data.token;
            //     // state.isAuthenticated = true;
            // })
            .addCase(userLoginForgetPasswordVerifyOtp.rejected, (state, action: any) => {
                state.loading = false;
                state.isAuthenticated = false;
            })
            .addCase(userLogout.pending, (state) => {
                state.loading = true;
            })
            .addCase(userLogout.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = undefined;
                state.token = undefined;
                state.isAuthenticated = false;
            })
            .addCase(userLogout.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const { setIsShowLoginModal, setCookieSettingAnswer, setNotificationToken, setShopData, setGlobalShop } = loginSlice.actions;

export default loginSlice.reducer;
