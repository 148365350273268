import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CountryCode } from "libphonenumber-js";
import { usePhone } from "../../../hooks/usePhone";
import * as Yup from "yup";
import Number from "../../../components/Number";
import { axiosPost } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { errorCode } from "../../../constants/common";
import { toast } from "react-toastify";
import ButtonCmp from "../../../components/ButtonCmp";
import { useTranslation } from "react-i18next";

export interface ILoginWithPhoneForm {
    phone: string;
}

interface IProps {
    handleNextStep: () => void;
    handlerLoginWithEmail: () => void;
    setPhone: React.Dispatch<React.SetStateAction<string>>;
    countryCode: CountryCode;
    setCountryCode: React.Dispatch<React.SetStateAction<CountryCode>>;
    setFormatedPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    setCountryDigit: React.Dispatch<React.SetStateAction<string>>;
}

const LoginWithPhone = ({ handleNextStep, handlerLoginWithEmail, setPhone, countryCode, setCountryCode, setFormatedPhoneNumber, setCountryDigit }: IProps) => {
    const { t } = useTranslation();
    const [isLoginWithPhoneLoading, setIsLoginWithPhoneLoading] = useState(false);
    const { isPhoneValid, setFormatPhone } = usePhone();
    const schema = Yup.object().shape({
        phone: Yup.string()
            .test("phone-validation", t("Invalid phone number"), (value) => {
                try {
                    if (!value || value.trim() === "") return true;
                    return isPhoneValid(value, countryCode);
                } catch (error) {
                    return false;
                }
            })
            .required(t("This field is required")),
    });

    const handleNumberChange = (country: CountryCode, phone: string, code: string) => {
        setCountryCode(country);
        setCountryDigit(code);
        const formatedPhoneNumber = setFormatPhone(code, phone, country);
        setFormatedPhoneNumber(formatedPhoneNumber);
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<ILoginWithPhoneForm>({
        resolver: yupResolver(schema) as any,
        defaultValues: {
            phone: "",
        },
    });

    const handleSubmitForm = async (data: ILoginWithPhoneForm) => {
        try {
            setIsLoginWithPhoneLoading(true);
            const response = await axiosPost(API.USER.LOGIN_WITH_PHONE, {
                phone: data.phone,
                phone_country_code: countryCode,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                handleNextStep();
                setPhone(data.phone);
                return;
            }
            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoginWithPhoneLoading(false);
        }
    };

    return (
        <div className="content">
            <h1 className="text-xl md:text-[32px] -tracking-[0.24px] md:-tracking-[0.384px] leading-6 md:leading-10 font-semibold text-txtcolor">{t("Login/Signup with Mobile")}</h1>
            <div className="mt-[30px] md:mt-10">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <label htmlFor="phone" className="fl-field-title-label">
                        {t("Enter your phone number")}
                    </label>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <div className="flex flex-col text-txtcolor">
                                <Number getNumber={onChange} errors={errors} onNumberChange={handleNumberChange} />
                            </div>
                        )}
                    />
                    <ButtonCmp loading={isLoginWithPhoneLoading} className={`btn_primary fl-btn w-full mt-5 opacity-100`} type="submit">
                        {t("Continue")}
                    </ButtonCmp>
                </form>
            </div>
            <div className="flex items-center mt-[30px]">
                <hr className="border-spacing-2 border-secondaryTxt opacity-20 w-full my-0" />
                <span className="px-4 uppercase font-medium -tracking-[0.12px] text-txtcolor">{t("Or")}</span>
                <hr className="border-spacing-2 border-secondaryTxt opacity-20 w-full my-0" />
            </div>
            <div className="text-center mt-[30px]">
                <button className="text-primary text-sm font-bold -tracking-[0.14px]" onClick={handlerLoginWithEmail}>
                    {t("Login with email")}
                </button>
            </div>
        </div>
    );
};

export default LoginWithPhone;
