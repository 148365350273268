import React from "react";
import LoginWithEmail from "./LoginWithEmail";
import CommonModal from "../../../components/CommonModal";

interface IProps {
    handlerLoginWithPhone: () => void;
    handlerForgetPassword: () => void;
    onCloseModal: () => void;
}

const LoginWithEmailController = ({ handlerLoginWithPhone, handlerForgetPassword, onCloseModal }: IProps) => (
    <CommonModal size="max-w-[600px]" handleClose={onCloseModal} bodyClass="md:pt-10 md:pb-10">
        <LoginWithEmail handlerLoginWithPhone={handlerLoginWithPhone} handlerForgetPassword={handlerForgetPassword} onCloseModal={onCloseModal} />
    </CommonModal>
);

export default LoginWithEmailController;
