import React, { useState } from "react";
import ButtonCmp from "../../../components/ButtonCmp";
import UploadComponent from "./UploadPhoto";
import { axiosPatch } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { errorCode } from "../../../constants/common";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { userMe } from "../../../redux/reducers/Login.slice";
import { useTranslation } from "react-i18next";
interface IProps {
    handleClose: () => void;
}

const CompleteProfileImageUpload = ({ handleClose }: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const authUserState = useAppSelector((data) => data.AuthUser);
    const [profileImageUrl, setProfileImageUrl] = useState<string | undefined>(undefined);
    const [isProfileImageUpdateLoading, setIsProfileImageUpdateLoading] = useState(false);
    const [imageUploadError, setImageUploadError] = useState<string | undefined>(undefined);
    const [uploadError, setUploadError] = useState<string>();

    const handleImageUploadComplete = async () => {
        try {
            setIsProfileImageUpdateLoading(true);

            if (profileImageUrl) {
                const response = await axiosPatch(API.USER.USER_PROFILE_IMAGE_UPDATE, {
                    profile_image_name: profileImageUrl,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    await dispatch(userMe({}));
                    handleClose();
                    return;
                }

                throw response.data;
            }
            handleClose();
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsProfileImageUpdateLoading(false);
        }
    };

    return (
        <div className="content">
            <div className="mt-4 mb-6 text-center">
                <h1 className="text-xl md:text-[32px] -tracking-[0.24px] md:-tracking-[0.384px] leading-6 md:leading-10 font-semibold">
                    {t("Welcome")} {authUserState.user?.first_name}!
                </h1>
                {authUserState.globalShop && authUserState.globalShop?.label && (
                    <p className="text-secondaryTxt text-sm font-medium mt-3 mb-8">
                        {t("Almost done! Add a profile picture so the team at")} {authUserState.globalShop?.label} {t("can recognize you.")}
                    </p>
                )}
            </div>
            <div className="flex justify-center items-center flex-col">
                <div className="flex justify-center flex-col w-[100px] h-[100px] md:h-[260px] md:w-[260px] items-center">
                    <UploadComponent
                        onPictureChange={(e) => {
                            setImageUploadError(undefined);
                            setProfileImageUrl(e);
                        }}
                        pictureName={profileImageUrl}
                        isInvalid={!!imageUploadError}
                        disabled={isProfileImageUpdateLoading}
                        error={uploadError}
                        setError={setUploadError}
                    />
                </div>
                {uploadError && <p className="text-red-500">{uploadError}</p>}
            </div>
            <div className="flex justify-center items-center">
                <p className="text-red-500 mt-[6px] w-1/3 flex justify-center">{imageUploadError ?? imageUploadError}</p>
            </div>
            <ButtonCmp className="btn_primary fl-btn w-full mt-5" onClick={handleImageUploadComplete} loading={isProfileImageUpdateLoading}>
                {t("Next")}
            </ButtonCmp>
        </div>
    );
};

export default CompleteProfileImageUpload;
