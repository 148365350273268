import React, { useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputWithLabel from "../../../components/InputWithLabel";
import * as Yup from "yup";
import ButtonCmp from "../../../components/ButtonCmp";
import { userLoginWithEmail } from "../../../redux/reducers/Login.slice";
import { COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX, errorCode } from "../../../constants/common";
import { LocalStorageKeys } from "../../../constants/keys";
import { toast } from "react-toastify";
import { HiEye } from "react-icons/hi2";
import { HiEyeOff } from "react-icons/hi";
import { useTranslation } from "react-i18next";

interface ILoginFormProps {
    email: string;
    password: string;
}

interface IProps {
    handlerLoginWithPhone: () => void;
    handlerForgetPassword: () => void;
    onCloseModal: () => void;
}

type FieldKey = "email" | "password";

const LoginWithEmail: React.FC<IProps> = ({ handlerLoginWithPhone, handlerForgetPassword, onCloseModal }: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isShowPassword, setisShowPassword] = useState(false);
    const [isLoginWithEmailLoading, setIsLoginWithEmailLoading] = useState(false);

    const schema = Yup.object({
        email: Yup.string().email(t("Please enter a valid email address")).required(t("This field is required")),
        password: Yup.string()
            .required(t("Please provide a password"))
            .min(6, t("Password must be at least 6 characters"))
            .trim()
            .matches(COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX, t("Include at least one uppercase, lowercase and either a digit or one of these special characters: !@#$%^&*_|")),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { isValid, errors },
        setError,
    } = useForm<ILoginFormProps>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: "",
        },
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    const HandleChange = async (data: ILoginFormProps) => {
        if (isValid) {
            setIsLoginWithEmailLoading(true);
            const payloadData = {
                email: data.email,
                password: data.password,
                remember_me: true,
            };
            const result = await dispatch(userLoginWithEmail(payloadData));

            setIsLoginWithEmailLoading(false);

            if (result.type === userLoginWithEmail.fulfilled.toString()) {
                localStorage.setItem(LocalStorageKeys.authToken, result.payload.data.token);
                onCloseModal();
            }

            if (result.type === userLoginWithEmail.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable && response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: "manual",
                            message: response.data[field][0],
                        });
                    });
                    return;
                }
                toast.error(response.message);
            }
        }
    };

    return (
        <div className="content">
            <h1 className="text-xl md:text-[32px] -tracking-[0.24px] md:-tracking-[0.384px] leading-6 md:leading-10 font-semibold mb-[30px] md:mb-10 text-txtcolor">{t("Getting started")}</h1>
            <form action="" onSubmit={handleSubmit(HandleChange)}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div className="mb-5 flex flex-col w-full">
                            <InputWithLabel
                                label={t("Email")}
                                placeholder={t("Email")}
                                handleChange={onChange}
                                value={value}
                                name="email"
                                inputClass={`${errors.email ? "is-invalid border-solid" : ""}`}
                            />
                            <p className="text-red-500">{errors.email && errors.email.message}</p>
                        </div>
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div className="flex flex-col w-full">
                            <InputWithLabel
                                buttonIcon={isShowPassword ? <HiEyeOff size={18} color="#8C9CBB" /> : <HiEye size={18} color="#8C9CBB" />}
                                handlerIconClick={() => setisShowPassword(!isShowPassword)}
                                type={`${isShowPassword ? "text" : "password"}`}
                                handleChange={onChange}
                                name="password"
                                value={value}
                                placeholder="**********"
                                label={t("Password")}
                                inputClass={`${errors.password ? "is-invalid border-solid" : ""}`}
                            />
                            <p className="text-red-500">{errors.password && errors.password.message}</p>
                        </div>
                    )}
                />
                <div className="flex justify-end">
                    <p className="text-[14px] font-[700] text-primary cursor-pointer py-2 pr-2" onClick={() => handlerForgetPassword()}>
                        {t("Forgot Password?")}
                    </p>
                </div>
                <ButtonCmp loading={isLoginWithEmailLoading} className={`btn_primary fl-btn w-full mt-5`} type="submit">
                    {t("Login")}
                </ButtonCmp>
            </form>
            <div className="flex items-center my-[30px]">
                <hr className="border-spacing-2 border-secondaryTxt opacity-20 w-full my-0" />
                <span className="px-4 uppercase font-medium -tracking-[0.12px] text-txtcolor">{t("Or")}</span>
                <hr className="border-spacing-2 border-secondaryTxt opacity-20 w-full my-0" />
            </div>
            <div className="text-center">
                <button className="text-primary text-sm font-bold -tracking-[0.14px] text-center" onClick={() => handlerLoginWithPhone()}>
                    {t("Login with mobile")}
                </button>
            </div>
        </div>
    );
};

export default LoginWithEmail;
