import React, { useState } from "react";
import CommonModal from "../../../components/CommonModal";
import CompleteProfile from "./CompleteProfile";
import CompleteProfileImageUpload from "./CompleteProfileImageUpload";
import { FaCheck, FaCircle } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
interface IProps {
    handleCloseModal: () => void;
}

const CompleteProfileModal = ({ handleCloseModal }: IProps) => {
    const { t } = useTranslation();
    const [currentFormStep, setCurrentFormStep] = useState<"FORM" | "IMAGE_UPLOAD">("FORM");
    return (
        <CommonModal size="max-w-[900px]" handleClose={handleCloseModal} isClosable={false} bodyClass="md:pt-10 md:pb-10">
            <div className="w-[80%] md:w-1/2 pb-10 mx-auto">
                <ol className="fwpo__steper ">
                    <li className="flex w-full items-center step_completed">
                        <div className="relative flex flex-col items-center">
                            <span className="step__round">
                                <FaCheck />
                            </span>
                            <div className="steper_text">{t("Create account")}</div>
                        </div>
                        <div className="border_start"></div>
                    </li>
                    <li className={`flex w-full items-center ${currentFormStep === "IMAGE_UPLOAD" ? "step_completed" : "step_inprogress"}`}>
                        <div className="relative flex flex-col items-center">
                            <span className="step__round">{currentFormStep === "IMAGE_UPLOAD" ? <FaCheck /> : <FaCircle />}</span>
                            <div className="steper_text">{t("Profile setup")}</div>
                        </div>
                        <div className="border_start"></div>
                    </li>
                    <li className={`last_step ${currentFormStep === "IMAGE_UPLOAD" ? "step_inprogress" : ""}`}>
                        <div className="relative flex flex-col items-center">
                            <span className="step__round">
                                <FaCircle />
                            </span>
                            <div className="steper_text">
                                <span className="hidden md:block">{t("Profile image upload")}</span>
                                <span className="block md:hidden">{t("Image upload")}</span>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
            {currentFormStep === "FORM" && <CompleteProfile handleClose={handleCloseModal} openNextStep={() => setCurrentFormStep("IMAGE_UPLOAD")} />}
            {currentFormStep === "IMAGE_UPLOAD" && <CompleteProfileImageUpload handleClose={handleCloseModal} />}
        </CommonModal>
    );
};

export default CompleteProfileModal;
