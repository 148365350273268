import React, { useState } from "react";
import { ELoginWithPhoneStep } from "../Login.interface";
import LoginWithPhone from "./LoginWithPhone";
import CommonModal from "../../../components/CommonModal";
import Otp from "../../../components/Otp";
import { CountryCode } from "libphonenumber-js";

interface IProps {
    handlerLoginWithEmail: () => void;
    onCloseModal: () => void;
}

const LoginWithPhoneController = ({ handlerLoginWithEmail, onCloseModal }: IProps) => {
    const [loginStep, setLoginStep] = useState<ELoginWithPhoneStep>(ELoginWithPhoneStep.LOGIN);
    const [phone, setPhone] = useState<string>("");
    const [countryCode, setCountryCode] = useState<CountryCode>("US");
    const [formatedPhoneNumber, setFormatedPhoneNumber] = useState<string>("");
    const [countryDigit, setCountryDigit] = useState<string>("1");

    return (
        <CommonModal size="max-w-[600px]" handleClose={onCloseModal} bodyClass="md:pt-10 md:pb-10">
            {loginStep === ELoginWithPhoneStep.OTP ? (
                <Otp handleNextStep={() => onCloseModal()} phone={phone} countryCode={countryCode} formatedPhoneNumber={formatedPhoneNumber} countryDigit={countryDigit} />
            ) : (
                <LoginWithPhone
                    handleNextStep={() => setLoginStep(ELoginWithPhoneStep.OTP)}
                    handlerLoginWithEmail={handlerLoginWithEmail}
                    setPhone={setPhone}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    setFormatedPhoneNumber={setFormatedPhoneNumber}
                    setCountryDigit={setCountryDigit}
                />
            )}
        </CommonModal>
    );
};

export default LoginWithPhoneController;
