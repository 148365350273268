import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import InputWithLabel from "../../../components/InputWithLabel";
import SelectBox from "../../../components/SelectBox";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonCmp from "../../../components/ButtonCmp";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX, GENDER_OPTIONS, errorCode } from "../../../constants/common";
import { axiosPost } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { toast } from "react-toastify";
import { userMe } from "../../../redux/reducers/Login.slice";
import { HiEyeOff } from "react-icons/hi";
import { HiEye } from "react-icons/hi2";
import { IoCloseCircle } from "react-icons/io5";
import { format, parse } from "date-fns";
import { useTranslation } from "react-i18next";

interface ICompleteProfile {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirmation: string;
    birthdate: string;
    gender: string;
    referral_code: string | undefined;
}

interface IGenderOptions {
    value: string;
    label: string;
}

interface IProps {
    handleClose: () => void;
    openNextStep: () => void;
}

const CompleteProfile = ({ handleClose, openNextStep }: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const authUserState = useAppSelector((data) => data.AuthUser);

    const [isCompleteProfileUploadLoading, setIsCompleteProfileUploadLoading] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

    const [customGenderOption, setCustomGenderOption] = useState<IGenderOptions[]>([]);
    const [referralConfig, setReferralConfig] = useState({ referee: 0, referee_type: "percentage" });

    useEffect(() => {
        const userGender = authUserState.user?.gender ?? "";
        const foundOption = GENDER_OPTIONS.find((go) => go.value === userGender);
        if (!foundOption && userGender) {
            const customOption = {
                label: userGender,
                value: userGender,
            };
            setCustomGenderOption([...GENDER_OPTIONS, customOption]);
        } else {
            setCustomGenderOption(GENDER_OPTIONS);
        }
        setValue("gender", userGender);
        const filterShopSetting = authUserState.shopData?.shop_settings?.find((shop: { type: string }) => shop.type === "referral_config");
        if (filterShopSetting) {
            setReferralConfig({
                referee: filterShopSetting.value?.referee || 0,
                referee_type: filterShopSetting.value?.referee_type || "",
            });
        } else {
            setReferralConfig({
                referee: 0,
                referee_type: "",
            });
        }
    }, [authUserState.user, authUserState.shopData?.shop_settings]);
    const schema = Yup.object({
        first_name: Yup.string().required(t("This field is required")),
        last_name: Yup.string().required(t("This field is required")),
        email: Yup.string().email(t("Please enter a valid email address")).required(t("This field is required")),
        password: Yup.string()
            .required(t("This field is required"))
            .min(6, t("Password must be at least 6 characters"))
            .trim()
            .matches(COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX, t("Include at least one uppercase, lowercase and either a digit or one of these special characters: !@#$%^&*_|")),
        password_confirmation: Yup.string()
            .required(t("This field is required"))
            .oneOf([Yup.ref("password"), null], t("Passwords do not match")),
        birthdate: Yup.string().required(t("This field is required")),
        gender: Yup.string().required(t("Please select gender")),
        referral_code: Yup.string().notRequired(),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { isValid, errors },
        setError,
        setValue,
    } = useForm<ICompleteProfile>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: authUserState.user?.first_name,
            last_name: authUserState.user?.last_name,
            email: authUserState.user?.email,
            password: "",
            password_confirmation: "",
            birthdate: "",
            gender: authUserState.user?.gender,
            referral_code: "",
        },
    });

    const handleSubmitCompleteProfileForm = async (payloadData: ICompleteProfile) => {
        if (isValid) {
            try {
                const data = {
                    ...payloadData,
                };
                data.birthdate = format(parse(data.birthdate, "dd-MM-yyyy", new Date()), "Y-MM-dd");

                setIsCompleteProfileUploadLoading(true);
                const response = await axiosPost(API.USER.UPDATE_USER_ACCOUNT, data, { shop_id: authUserState.globalShop?.value });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    await dispatch(userMe({}));
                    openNextStep();
                    return;
                }

                if (response.data?.status === errorCode.unprocessable) {
                    Object.keys(response.data.data).forEach((field) => {
                        setError(field as "email", {
                            type: "manual",
                            message: response.data.data[field][0],
                        });
                    });
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsCompleteProfileUploadLoading(false);
            }
        }
    };
    return (
        <div className="content">
            <h1 className="text-xl md:text-[32px] text-center -tracking-[0.24px] md:-tracking-[0.384px] leading-6 md:leading-10 font-semibold mt-4 mb-6">{t("Complete your profile")}</h1>

            <form onSubmit={handleSubmit(handleSubmitCompleteProfileForm)}>
                <div className="flex flex-wrap justify-center -mx-4">
                    <div className="w-full px-4">
                        <div className="flex flex-wrap -mx-4">
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="mb-4 w-full md:w-1/2 px-4">
                                        <InputWithLabel
                                            label={t("First Name")}
                                            type="text"
                                            placeholder={t("Enter your first name")}
                                            handleChange={onChange}
                                            value={value}
                                            name="first_name"
                                            disabled={isCompleteProfileUploadLoading}
                                        />
                                        <p className="text-red-500">{errors.first_name && errors.first_name.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="mb-4 w-full md:w-1/2 px-4">
                                        <InputWithLabel
                                            label={t("Last Name")}
                                            type="text"
                                            placeholder={t("Enter your last name")}
                                            handleChange={onChange}
                                            value={value}
                                            name="last_name"
                                            disabled={isCompleteProfileUploadLoading}
                                        />
                                        <p className="text-red-500">{errors.last_name && errors.last_name.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="email"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="mb-4 w-full md:w-1/2 px-4">
                                        <InputWithLabel
                                            label={t("Email")}
                                            type="text"
                                            placeholder={t("Enter your Email")}
                                            handleChange={onChange}
                                            value={value}
                                            name="email"
                                            disabled={isCompleteProfileUploadLoading}
                                        />
                                        <p className="text-red-500">{errors.email && errors.email.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="gender"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="mb-4 w-full md:w-1/2 px-4">
                                        <SelectBox
                                            placeholder={t("Select your gender")}
                                            name="gender"
                                            label={t("Gender")}
                                            allowClear={false}
                                            options={customGenderOption}
                                            searchable={true}
                                            onChange={(option: IGenderOptions | undefined) => onChange(option?.value)}
                                            value={value && customGenderOption.find((option) => option.value === value)}
                                            disabled={isCompleteProfileUploadLoading}
                                        />
                                        <p className="text-red-500">{errors.gender && errors.gender.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="password"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="mb-4 w-full md:w-1/2 px-4">
                                        <InputWithLabel
                                            label={t("Create Password")}
                                            buttonIcon={isShowPassword ? <HiEyeOff size={18} color="#8C9CBB" /> : <HiEye size={18} color="#8C9CBB" />}
                                            handlerIconClick={() => setIsShowPassword(!isShowPassword)}
                                            type={`${isShowPassword ? "text" : "password"}`}
                                            placeholder="••••••••••••••"
                                            handleChange={onChange}
                                            value={value}
                                            name="password"
                                            disabled={isCompleteProfileUploadLoading}
                                        />
                                        <p className="text-red-500">{errors.password && errors.password.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="password_confirmation"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="mb-4 w-full md:w-1/2 px-4">
                                        <InputWithLabel
                                            label={t("Confirm Password")}
                                            buttonIcon={isShowConfirmPassword ? <HiEyeOff size={18} color="#8C9CBB" /> : <HiEye size={18} color="#8C9CBB" />}
                                            handlerIconClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                                            type={`${isShowConfirmPassword ? "text" : "password"}`}
                                            placeholder="••••••••••••••"
                                            handleChange={onChange}
                                            value={value}
                                            name="password_confirmation"
                                            disabled={isCompleteProfileUploadLoading}
                                        />
                                        <p className="text-red-500">{errors.password_confirmation && errors.password_confirmation.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="birthdate"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="mb-4 w-full md:w-1/2 px-4">
                                        <InputWithLabel
                                            label={t("Date of birth")}
                                            name="birthdate"
                                            inputClass="ba_form_control"
                                            placeholder={t("DD-MM-YYYY")}
                                            handleChange={(e) => {
                                                if ((e.nativeEvent as any)?.inputType === "deleteContentBackward") {
                                                    onChange(e.target.value);
                                                    return;
                                                }

                                                let input = e.target.value.replace(/\D/g, "");
                                                let formattedInput = "";

                                                if (input.length > 1) {
                                                    formattedInput += `${input.substring(0, 2)}-`;
                                                    input = input.substring(2);
                                                }

                                                if (input.length > 1) {
                                                    formattedInput += `${input.substring(0, 2)}-`;
                                                    input = input.substring(2);
                                                }

                                                formattedInput += input.substring(0, 4);

                                                e.target.value = formattedInput;
                                                onChange(formattedInput);
                                            }}
                                            buttonIcon={value ? <IoCloseCircle className="text-secondaryTxt text-xl" /> : null}
                                            handlerIconClick={() => onChange("")}
                                            value={value}
                                            disabled={isCompleteProfileUploadLoading}
                                        />
                                        <p className="text-red-500">{errors.birthdate && errors.birthdate.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="referral_code"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <div className="mb-5 md:mb-4 w-full md:w-1/2 px-4">
                                        <InputWithLabel
                                            label={t("Referral code (Optional)")}
                                            type="text"
                                            placeholder={t("Enter Discount")}
                                            handleChange={(e) => onChange(e.target.value.toUpperCase())}
                                            value={value ?? ""}
                                            inputClass="bg-transparent border-dashed border-2 border-secondaryTxt uppercase"
                                            name="referral_code"
                                            disabled={isCompleteProfileUploadLoading}
                                        />
                                        <p className="text-red-500">{errors.referral_code && errors.referral_code.message}</p>
                                        <p className="text-center text-secondaryTxt text-xs font-normal leading-4 -tracking-[0.03px] mt-2">
                                            {t("Enter a referral code to receive")}{" "}
                                            <span className="font-semibold">{referralConfig.referee_type === "percentage" ? `${referralConfig.referee}%` : `$${referralConfig.referee}`}</span>{" "}
                                            {t("your first appointment")}
                                        </p>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                {authUserState.globalShop && authUserState.globalShop?.label && (
                    <p className="text-txtcolor leading-4 font-medium text-[10px] -tracking-[0.12px]">
                        {t("By signing up, I agree to")} {authUserState.globalShop?.label}
                        &apos;s
                        <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="text-primary">
                            {" "}
                            {t("Terms of Service")}{" "}
                        </a>{" "}
                        {t("and")}
                        <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="text-primary">
                            {" "}
                            {t("Privacy Policy")}{" "}
                        </a>{" "}
                        {t("and I would like to be alerted for personalized offerings, discounts, and more.")}
                    </p>
                )}
                <ButtonCmp type="submit" className="btn_primary fl-btn w-full mt-5" loading={isCompleteProfileUploadLoading} disabled={isCompleteProfileUploadLoading}>
                    {uiState.isMobile ? t("Next") : t("Continue")}
                </ButtonCmp>
            </form>
        </div>
    );
};

export default CompleteProfile;
