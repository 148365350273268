import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { loginSlice } from "./reducers/Login.slice";
import { bookingSlice } from "./reducers/Booking.slice";
import { uiSlice } from "./reducers/Ui.slice";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["AuthUser", "Booking", "UiStates"],
};

const rootReducer = combineReducers({
    AuthUser: loginSlice.reducer,
    Booking: bookingSlice.reducer,
    UiStates: uiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
