import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import InputWithLabel from "../../../components/InputWithLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonCmp from "../../../components/ButtonCmp";
import { axiosPost } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { errorCode } from "../../../constants/common";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface IForgetPasswordFormProps {
    email: string;
}

interface IProps {
    handleNextStep: () => void;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const Forgotpassword = ({ handleNextStep, setEmail }: IProps) => {
    const { t } = useTranslation();
    const [isForgetPasswordSendOtpLoading, setIsForgetPasswordSendOtpLoading] = useState(false);

    const schema = Yup.object({
        email: Yup.string().email(t("Please enter a valid email address")).required(t("This field is required")),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { isValid, errors },
        setError,
    } = useForm<IForgetPasswordFormProps>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
        },
    });

    const HandleSendCodeClick = async (data: IForgetPasswordFormProps) => {
        if (isValid) {
            try {
                setIsForgetPasswordSendOtpLoading(true);
                const response = await axiosPost(API.USER.FORGET_PASSWORD_SEND_OTP, {
                    email: data.email,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    setEmail(data.email);
                    handleNextStep();
                    return;
                }

                if (response.data?.status === errorCode.unprocessable && response.data.data) {
                    Object.keys(response.data.data).forEach((field) => {
                        setError(field as "email", {
                            type: "manual",
                            message: response.data.data[field][0],
                        });
                    });
                    return;
                }
                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsForgetPasswordSendOtpLoading(false);
            }
        }
    };

    return (
        <div className="content">
            <h1 className="text-xl md:text-[32px] -tracking-[0.24px] md:-tracking-[0.384px] leading-6 md:leading-10 font-semibold mb-4 text-txtcolor">{t("Forgot Password?")}</h1>
            <div className="mb-[30px] md:mb-10">
                <p className=" text-secondaryTxt opacity-7 tracking-[-0.14px] text-[14px] font-normal">
                    {t("Enter the registered email. We will send a text message with a 4-digit code to reset your password.")}
                </p>
            </div>
            <form action="" onSubmit={handleSubmit(HandleSendCodeClick)}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div className="mb-4 flex flex-col w-full">
                            <InputWithLabel
                                label={t("Email")}
                                placeholder={t("Enter your email")}
                                handleChange={onChange}
                                value={value}
                                name="email"
                                disabled={isForgetPasswordSendOtpLoading}
                                inputClass={`${errors.email ? "is-invalid border-solid" : ""}`}
                            />
                            <p className="text-red-500">{errors.email && errors.email.message}</p>
                        </div>
                    )}
                />
                <ButtonCmp type="submit" className="btn_primary fl-btn w-full mt-5" disabled={!isValid || isForgetPasswordSendOtpLoading} loading={isForgetPasswordSendOtpLoading}>
                    {t("Send code")}
                </ButtonCmp>
            </form>
        </div>
    );
};

export default Forgotpassword;
