import React from "react";
import { Check, CalendarNotifi, DiscountIcon, CalenderRed } from "../../../SvgColor/svgIcons";
import { formatDistanceToNow } from "date-fns";
import { INotification } from "../../../../Interface/notification.interface";

interface IProps {
    className: string;
    isNegative: boolean;
    notificationRecord: INotification;
}

const NotificationCardComponent = ({ className, isNegative, notificationRecord }: IProps) => {
    const getCardIcon = () => {
        if (["pending"].includes(notificationRecord.booking_status)) {
            return <CalendarNotifi fillColor="var(--icon-color)" />;
        } else if (["confirmed", "completed"].includes(notificationRecord.booking_status)) {
            return <Check fillColor="var(--icon-color)" />;
        } else if (false) {
            // it will be used for referal code used, for now disabled
            <DiscountIcon fillColor="var(--icon-color)" />;
        } else if (["no_show", "declined", "canceled", "request_canceled", "expired"].includes(notificationRecord.booking_status)) {
            return <CalenderRed fillColor="var(--icon-color)" />;
        }
    };

    return (
        <div className={className}>
            <div>
                <div
                    className={`w-[30px] h-[30px] rounded-[10px] md:h-[50px] md:w-[50px] justify-center flex items-center px-2 md:rounded-2xl ${
                        isNegative ? "bg-danger bg-opacity-30" : "bg-inputbg"
                    } `}
                >
                    {getCardIcon()}
                </div>
            </div>
            <div className="w-full">
                <div className="flex justify-between mb-[5px] md:mb-2">
                    <h1 className="tracking-[-0.16px] text-sm md:text-base font-medium text-txtcolor">{notificationRecord.name}</h1>
                    <p className="text-secondaryTxt tracking-[-0.14px] text-xs font-normal">
                        {formatDistanceToNow(new Date(notificationRecord.created_at), {
                            addSuffix: true,
                        })}
                    </p>
                </div>
                <div className="w-[90%]">
                    <p className="text-secondaryTxt tracking-[-0.12px] md:tracking-[-0.14px] text-xs md:text-sm font-normal leading-[140%]">{notificationRecord.description}</p>
                </div>
            </div>
        </div>
    );
};

export default NotificationCardComponent;
